import React, { useEffect, useState, createRef } from "react";
import { Button, Checkbox, Icon, Modal, Input } from "semantic-ui-react";
import { Layout, Step, SectionView } from "./TypeDef";
import XDGrid from "./XDGrid";
import XDGraph from "./XDGraph";
import XDSummary from "./XDSummary";
import { xdToView } from "./Algorithm";
import { Loc, sectionMargin, NodeDetail, SectionDetail, StepDetail, CellDetail } from "./XdDetail";
import StepEditor from "./StepEditor";
import XDRole from "./XDRole";
import XDCase from "./XDCase";
import XDTest from "./XDTest";
import XDJourney from "./XDJourney";
import XDDiagram from "./XDDiagram";
import IHGame from "../IHGAME/IHGame";
import { query } from "ismor-lib/utils/DuckLib";
import Master from "../DP/Master";

// import XDJson from "./XDJson";
// import XDScene from "./XDScene";
// import XDSpec from "./XDSpec";
// import XDSlide from "./XDSlide";

const XD = (props: any) => {
  const [selectedMode, setSelectedMode] = useState(
    props.forClient ? "Grid" 
    : props.debug ? "Grid"
    : "Summary");
  const [showTool, setShowTool] = useState(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showSlim, setShowSlim] = useState<boolean>(false);
  const [moveLine, setMoveLine] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [showSections, setShowSections] = useState<boolean>(false);
  const [addMode, setAddMode] = useState(false);
  const [newXd, setNewXd] = useState({name: "New XD"});
  const [loc, setLoc] = useState<Loc | null>(null);
  const [cellDetail, setCellDetail] = useState(false);
  const [stepDetail, setStepDetail] = useState(false);
  const [nodeDetail, setNodeDetail] = useState(false);
  const [sectionDetail, setSectionDetail] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(null);
  const [foldedSections, setFoldedSections] = useState<number[]>([]);
  const [urlRoute, setUrlRoute] = useState(true);
  const [windowWidth, setWindowWidth] = useState(typeof window !== "undefined" ? document.documentElement.clientWidth : 0);
  // const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
  const [downloadMenu, setDownloadMenu] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [publishDialog, setPublishDialog] = useState<any>(null);
  const [testDataReady, setTestDataReady] = useState<string|null>(null);
  const [issueTestResult, setIssueTestResult] = useState<any>(null);
  
  useEffect(() => {
    props.onEvent({message: "LoadXD", params: {}});

    const changeWidth = () => {
      // console.log(document.documentElement.clientWidth)
      setWindowWidth(document.documentElement.clientWidth);
    }
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    }
  }, []);

  useEffect(() => {
    if (!urlRoute) return;
    const mode = props.urlParams.get('mode');
    if (mode) {
      setSelectedMode(mode);
      setUrlRoute(false);
    }
  }, [props.urlParams])

  useEffect(() => {
    setLoc(null);
    if (!props.forClient) {
      setTestDataReady(null);
      props.onEvent({message: "GetXdTestSummary", params: {
        xdId: props.xdId, setDataReady: setTestDataReady}});
    }
  }, [props.xdId])

  useEffect(() => {
    if (testDataReady === "ok" && props.duckCon && !props.forClient) {
      (async () => {
        let issueResult = await query(`
          with temp as (
            from xdtestresult 
            where datetime is distinct from null
            order by issueId, datetime
          )
          from temp
          select 
            last(datetime) datetime,
            issueId,
            last(tester) tester,
            last(status) status,
            last(step) step,
            last(node) node,
          group by all
          order by datetime desc
          `, props.duckCon);
        setIssueTestResult(Object.fromEntries(
          issueResult.map((issueItem: any) => 
            [issueItem?.issueId, issueItem])));
      })();
    }
  }, [testDataReady]);
 
  const showNodeDetail = (loc: Loc) => {
    return
    setLoc(loc);
    if (loc.id === null)
      setNodeDetail(true);
  }

  const doneNodeDetail = (save: boolean = false, data: any) => {
    if (!props.xdedit) return;
    if (save && data?.mode == "Add")
      props.onEvent({message: "AddNode", params: { data: data }});
    setNodeDetail(false);
    setLoc(null);
  }

  const doneSectionDetail = (save: boolean = false, data: any) => {
    if (!props.xdedit) return;
    if (save)
      props.onEvent({
        message: "EditSection", 
        params: {
          sectionIndex: selectedSectionIndex, 
          ...data
        }});
    setSectionDetail(false);
    setSelectedSectionIndex(null);
  }

  const showRowDetail = (loc: Loc) => {
    setLoc(loc);
    setStepDetail(true);
  }

  const doneRowDetail = (save: boolean = false, data: any) => {
    if (!props.xdedit) return;
    if (save && loc)
      props.onEvent({message: "SaveXdStep", params: {step: loc.step}});
    setStepDetail(false);
    setLoc(null);
  }

  const showCellDetail = (loc: Loc) => {
    setLoc(loc);
    setCellDetail(true)
  }

  const doneCellDetail = (save: boolean = false) => {
    if (!props.xdedit) return;
    if (save && loc)
      props.onEvent({message: "SaveXdStep", params: {step: loc.step}});
    setCellDetail(false);
    // setLoc(null);
  }

  const remove = (loc: Loc) => {
    if (!props.xdedit) return;
    let step: Step = loc.step;
    step.nodeDetail = Object.fromEntries(
      Object.entries(step.nodeDetail)
        .filter((item: [string, any]) => item[0] !== loc.id)
    );
    props.setProp(`xddata.steps.${loc.step.id}`, step);
    props.onEvent({message: "SaveXdStep", params: {step: step}});
    setCellDetail(false);
  }

  const addStep = (step: Step, stepIndex: number, sectionIndex: number) => {
    if (!props.xdedit) return;
    props.onEvent({
      message: "AddXdStep", 
      params: {step, stepIndex, sectionIndex}
    });
  }

  const swapStep = (indices: [number, number]) => {
    if (!props.xdedit) return;
    props.onEvent({
      message: "SwapStep", 
      params: {indices: indices}
    });
  }
  
  // const span = showDetail ? 85 : 23;
  const span = showDetail ? 85 : 30;
  const layout: Layout = {
    // width: loc !== null ? 1200 : 1600,
    width: loc !== null ? windowWidth - 400 : windowWidth,
    span: span,
    height: Object.keys(props.xddata.steps).length * span 
            + props.xddata.sections.length * sectionMargin,
  }
  
  // let xddata = Object.assign({}, props.xddata);
  let xddata = JSON.parse(JSON.stringify(props.xddata));
  const nodeMaster = props.xddata.nodeMaster;
  xddata.nodes = xddata.nodes.map((id: string) => ({id: id, ...nodeMaster[id]}));
  const cols = xddata.nodes.length + 1;
  const view  = xdToView(xddata, layout, foldedSections);

  let sectionRefs: any[] = [];
  let stepRefs: any = {};
  for (const section of view.sectionViews) {
    sectionRefs.push(createRef());
  }
  for (const step of view.stepViews) {
    stepRefs[step.id] = createRef();
  }

  const stepSequence = Object.fromEntries(
    xddata?.sections.flatMap((section: any, sectionIndex: number) => (
      section.steps.map((stepId: any, stepIndex: number) => 
        [ stepId, 
          {
            id: stepId,
            label: `${sectionIndex + 1}.${stepIndex + 1}`, 
            key: stepIndex + sectionIndex * 10000
          }]
      )
    ))
  );

  const sections = (xddata?.sections || []).map((section: any, sectionIndex: number) => (
    {
      ...section,
      steps: section.steps.map((id: any, stepIndex: number) => (
        {
          ...xddata.steps[id], 
          stepIndex,
          sectionIndex, 
          sectionName: section.name,
          seq: stepSequence[id].label,
          // To exclude inactive steps
          nextStepsList: xddata.steps[id].dependOn.length === 0 ? []
            : xddata.steps[id].dependOn
              .map((id: string) => stepSequence[id])
              .sort((a: any, b: any) => a.key < b.key ? -1 : 1),
          // Normal print
          nextSteps: xddata.steps[id].dependOn.length === 0 ? "Final" 
            : xddata.steps[id].dependOn
              .map((id: string) => stepSequence[id])
              .sort((a: any, b: any) => a.key < b.key ? -1 : 1)
              .map((item: any) => item?.label)
              .join(", ")
        }
      ))
    }
  ));

  const nodeDetailCount = Object.values(props.xddata.steps)
                                .flatMap((step: any) => 
                                  Object.values(step.nodeDetail)
                                    .map((nodeDetailItem: any) => (
                                      (nodeDetailItem?.doc && (nodeDetailItem?.doc?.length > 0)) ? [1, 1] : [0, 1]
                                  )))
                                .reduce((acc: number[], cur: number[]) => 
                                  [acc[0] + cur[0], acc[1] + cur[1]], [0, 0]);

  let roleData: string[] = xddata.nodes.reduce((acc: any, node: any) => {
    const nodeName = xddata.nodeMaster[node.id].name;
    if (!(nodeName in acc)) {
      acc[nodeName] = {
        nameEn: xddata.nodeMaster[node.id].nameEn || xddata.nodeMaster[node.id].name,
        nodeIdList: []
      }
      acc[nodeName].nodeIdList.push(node.id);
    }
    return acc;
  }, {});

  return(
    <div style={{width: "100%", height: "100%", position: "relative"}}>

      {/* Top menu */}
      <div 
        style={{
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: "center", 
          borderBottom: "solid black 1px"
        }}>
        
        {/* Left-side menu */}
        <div style={{display: "flex", alignItems: "center"}}>

          {/* Select XD */}
          <select            
            style={{width: "200px"}}
            value={props.xdId || ""}
            onChange={(e: any) => {
              props.onEvent({message: "ChangeXD", params: {xdId: e.target.value}});
            }}>
            {(props.xdmaster?.allXd || [])
              .sort((a: any, b: any) => a.name < b.name ? -1 : 1)
              .map((item: any, index: number) => (
                <option 
                  key={index}
                  value={item.id}>
                  {item.name}
                </option>
              ))
            }
          </select>
          
          {/* Add XD */}
          <div style={{marginLeft: "10px"}}>
            <Icon
              name="add"
              color="green"
              onClick={(e: any) => setAddMode(true)}
            />
          </div>

          {props.debug &&
          <div style={{marginLeft: "10px"}}>
            <Icon
              name="upload"
              color="blue"
              onClick={(e: any) => {
                props.onEvent({message: "XdPublish", params: {setPublishDialog}});
              }}
            />
          </div>}

          <div style={{marginLeft: "10px", display: "flex"}}>
            <div>
              {`${props.xddata.sections
                .map((section: any) => section.steps.length)
                .reduce((acc: number, cur: number) => acc + cur, 0)} steps`}
            </div>
            <div style={{marginLeft: "10px"}}>
              {`${nodeDetailCount[0]}/${nodeDetailCount[1]} node details`}
            </div>
          </div>


          {/* Shortcut to sections */}
          {["Grid", "Graph"].includes(selectedMode) &&
          <div style={{marginLeft: "15px"}}>
            <div
              style={{
                padding: "0 5px 0 5px", 
                margin: "0 1px 0 1px",
                cursor: "pointer",
                backgroundColor: "pink",
                borderRadius: "3px",
                color: "white"
              }}
              onClick={(e: any) => setShowSections(!showSections)}>
              <Icon name="angle double down" />
            </div>
            {showSections &&
            <div style={{position: "absolute", backgroundColor: "white"}}>
              {view?.sectionViews.map((sectionView: SectionView, sectionIndex: number) => (
                <div 
                  key={sectionIndex}
                  style={{
                    backgroundColor: "#e75480", 
                    color: "white", 
                    fontWeight: "bold",
                    margin: "0px 2px 2px 2px",
                    cursor: "pointer"
                  }}
                  onClick={(e: any) => {
                    sectionRefs[sectionIndex].current.scrollIntoView(true);
                    setSelectedSectionIndex(sectionIndex);
                    setShowSections(false);
                  }}>
                  {`${sectionIndex + 1}. ${sectionView.name}`}
                </div>
              ))}
            </div>}
          </div>}

          {["Grid", "Graph"].includes(selectedMode) &&
          <div style={{marginLeft: "15px"}}>
            <div
              style={{
                padding: "0 5px 0 5px", 
                margin: "0 1px 0 1px",
                cursor: "pointer",
                backgroundColor: "gray",
                borderRadius: "3px",
                color: "white"
              }}
              onClick={(e: any) => setShowSettings(!showSettings)}>
              <Icon name="setting" />
            </div>
            {showSettings &&
            <div 
              style={{
                position: "absolute", 
                backgroundColor: "white",
                padding : "5px",
                border: "solid black 1px"
              }}>
              {/* Show details for Grid */}
              {selectedMode === "Grid" &&
              <div style={{marginLeft: "10px"}}>
                <Checkbox 
                  checked={showTool} 
                  onClick={() => setShowTool(!showTool)} 
                  label="Tool"
                />
              </div>}
              
              {/* Show details for Graph */}
              {selectedMode === "Graph" &&
              <div style={{marginLeft: "10px"}}>
                <Checkbox
                  color="blue"
                  label="Node Detail"
                  checked={showDetail}
                  onClick={(e: any) => {
                    setShowDetail(!showDetail);
                    if (showSlim)
                      setShowSlim(false);
                  }}
                />
              </div>}

              {/* Slim box */}
              {selectedMode === "Graph" &&
              <div style={{marginLeft: "10px"}}>
                <Checkbox
                  color="blue"
                  label="Slim"
                  checked={showSlim}
                  onClick={(e: any) => {
                    setShowSlim(!showSlim);
                    if (showDetail)
                      setShowDetail(false);
                  }}
                />
              </div>}

              {/* Move lines for Graph */}
              {selectedMode === "Graph" &&
              <div style={{marginLeft: "10px"}}>
                <Checkbox
                  disabled={!props.xdedit}
                  color="blue"
                  label="Move line"
                  checked={moveLine}
                  onClick={(e: any) => {
                    if (!props.xdedit) return;
                    setMoveLine(!moveLine)
                  }}
                />
              </div>}
              
              {["Graph", "Grid"].includes(selectedMode) &&
              <div style={{marginLeft: "10px"}}>
                <Checkbox
                  disabled={!props.xdedit}
                  color="blue"
                  label="Show Inactive"
                  checked={showInactive}
                  onClick={(e: any) => {
                    if (!props.xdedit) return;
                    setShowInactive(!showInactive)
                  }}
                />
              </div>}
            </div>}
          </div>}

          {/* Export Grid to Excel */}
          {selectedMode === "Grid" &&
          <div style={{marginLeft: "10px"}}>
            <div
              style={{
                backgroundColor: "green", 
                color: "white", 
                padding: "0 5px 0 5px", 
                margin: "0 1px 0 1px",
                cursor: "pointer",
                borderRadius: "3px",
              }}
              onClick={(e: any) => {
                if (!props.xdedit) return;
                props.onEvent({message: "ExportGridToExcel", params: {}});
              }}>
              <Icon name="file excel" />
            </div>
          </div>}

          {/* Export Spec to Word */}
          <div style={{marginLeft: "10px", position: "relative"}}>
            <div
              style={{
                backgroundColor: props.webviewReady ? "red" : "orange", 
                color: "white", 
                padding: "0 5px 0 5px", 
                margin: "0 1px 0 1px",
                cursor: "pointer",
                borderRadius: "3px",
              }}
              onClick={(e: any) => {
                if (!props.xdedit) return;
                setDownloadMenu(!downloadMenu);
              }}>
              <Icon 
                name="cloud download" 
                disabled={!props.xdedit}
              />
            </div>
            {downloadMenu &&
            <div 
              style={{
                position: "absolute", top: 20, left: 2, zIndex: 100,
                backgroundColor: "white", padding: "0 5px 0 5px",
                width: "200px", border: "solid black 1px"
              }}>
              <div style={{cursor: "pointer", display: "flex", justifyContent: "space-between"}}
                onClick={(e: any) => {
                  if (!props.xdedit) return;
                  props.onEvent({
                    message: "DownloadSpec", 
                    params: {
                      sections: sections,
                      view: view,
                      layout: layout,
                      xddata: xddata,
                      cols: cols,
                      roleData: roleData,
                      role: null,
                      webviewReady: props.webviewReady,
                    }
                  });
                  setDownloadMenu(false);
                }}>
                <span>All docs</span>
                <div>
                  <span>TH|</span>
                  <span
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (!props.xdedit) return;
                      props.onEvent({
                        message: "DownloadSpec", 
                        params: {
                          downloadEn: true,
                          sections: sections,
                          view: view,
                          layout: layout,
                          xddata: xddata,
                          cols: cols,
                          roleData: roleData,
                          role: null,
                          webviewReady: props.webviewReady,
                        }
                      });
                      setDownloadMenu(false);
                    }}>
                    EN
                  </span>
                </div>
              </div>
              {Object.keys(roleData).map((role: string, nodeIndex: number) => 
                <div 
                  key={nodeIndex}
                  style={{cursor: "pointer", display: "flex", justifyContent: "space-between"}}
                  onClick={(e: any) => {
                    if (!props.xdedit) return;
                    props.onEvent({
                      message: "DownloadSpec", 
                      params: {
                        sections: sections,
                        view: view,
                        layout: layout,
                        xddata: xddata,
                        cols: cols,
                        roleData: roleData,
                        role: role,
                        webviewReady: props.webviewReady,
                      }
                    });
                    setDownloadMenu(false);
                  }}>
                  <span>{role}</span>
                  <div>
                    <span>TH|</span>
                    <span
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (!props.xdedit) return;
                        props.onEvent({
                          message: "DownloadSpec", 
                          params: {
                            downloadEn: true,
                            sections: sections,
                            view: view,
                            layout: layout,
                            xddata: xddata,
                            cols: cols,
                            roleData: roleData,
                            role: role,
                            webviewReady: props.webviewReady,
                          }
                        });
                        setDownloadMenu(false);
                      }}>
                      EN
                    </span>
                  </div>
              </div>)}              
            </div>}
          </div>

        </div>

        {/* Mode selection */}
        <div style={{display: "flex", alignItems: "flex-end"}}>
          {!props.forClient &&
          <>
          <Mode name="Summary" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Case" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Test" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Game Sim" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          </>}
          <Mode name="Grid" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Graph" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Role" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Diagram" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Journey" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>

          {/* Move to top level */}
          {/* {props.debug &&
          <>
          <Mode name="Master" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          </>} */}

          {/* <Mode name="Slide" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          <Mode name="Test Scene" selectedMode={selectedMode} setSelectedMode={setSelectedMode}/> */}
        </div>
      </div>
      
      {/* Main content */}
      <div style={{height: "92vh", overflow: "auto"}}>
        {selectedMode === "Grid" ?
        <XDGrid
          xdedit={props.xdedit}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={xddata}
          xdmaster={props.xdmaster}
          cols={cols}
          view={view}
          sections={sections}
          sectionRefs={sectionRefs}
          showTool={showTool}
          addStep={addStep}
          swapStep={swapStep}
          foldedSections={foldedSections}
          setFoldedSections={setFoldedSections}
          loc={loc}
          showNodeDetail={showNodeDetail}
          selectedSectionIndex={selectedSectionIndex}
          setSelectedSectionIndex={setSelectedSectionIndex}
          setSectionDetail={setSectionDetail}
          showRowDetail={showRowDetail}
          showCellDetail={showCellDetail}
          remove={remove}
        />

        : selectedMode === "Graph" ?
        <XDGraph
          xdId={props.xdId}
          debug={props.debug}
          xdedit={props.xdedit}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={xddata}
          xdmaster={props.xdmaster}
          view={view}
          sections={sections}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          showSlim={showSlim}
          moveLine={moveLine}
          sectionRefs={sectionRefs}
          stepRefs={stepRefs}
          layout={layout}
          addStep={addStep}
          swapStep={swapStep} 
          foldedSections={foldedSections}
          setFoldedSections={setFoldedSections}         
          loc={loc}
          selectedSectionIndex={selectedSectionIndex}
          setSelectedSectionIndex={setSelectedSectionIndex}
          setSectionDetail={setSectionDetail}
          setLoc={setLoc}
          setStepDetail={setStepDetail}
          doneRowDetail={doneRowDetail}
          nodeMaster={nodeMaster}
          remove={remove}
          doneCellDetail={doneCellDetail}
          trigger={props.trigger}
          urlParams={props.urlParams}
          docInfo={props.docInfo}
        />

        : selectedMode === "Summary" ?
        <XDSummary
          xdId={props.xdId}
          xdedit={props.xdedit}
          onEvent={props.onEvent}
          setProp={props.setProp}      
          xdmaster={props.xdmaster}
          xddata={props.xddata}
          sections={sections}
          view={view}
          duckCon={props.duckCon}
          testDataReady={testDataReady}
          ihGameList={props.ihGameList}
          ihGameStatList={props.ihGameStatList}
          toWebDownloadUrl={props.toWebDownloadUrl}
          clientUserDict={props.clientUserDict}
        />

        : selectedMode === "Journey" ?
        <XDJourney
          onEvent={props.onEvent}
          xdId={props.xdId}
          xddata={props.xddata}
          setProp={props.setProp}
          xdJourneyDataState={props.xdJourneyDataState}
          duckCon={props.duckCon}
          toDownloadUrl={props.toDownloadUrl}
        />

        : selectedMode === "Diagram" ?
        <XDDiagram
          onEvent={props.onEvent}
          xdId={props.xdId}
          xddata={props.xddata}
          xdJourneyDataState={props.xdJourneyDataState}
          duckCon={props.duckCon}
        />

        : selectedMode === "Role" ?
        <XDRole
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={props.xddata}
          view={view}
          setLoc={setLoc}
          loc={loc}          
          trigger={props.trigger}
          docInfo={props.docInfo}
        />

        : selectedMode === "Case" ?
        <XDCase
          xdId={props.xdId}
          user={props.user}
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={props.xddata}
          view={view}
          docInfo={props.docInfo}
          xdCases={props.xdCases}
          priorityDict={props.priorityDict}
          dealOngoing={props.dealOngoing}
          issueLatestUpdates={props.issueLatestUpdates}
          duck={props.duck}
          duckCon={props.duckCon}
          duckLoaded={props.duckLoaded}
          xdProblemDict={props.xdProblemDict}
          xdTestList={props.xdTestList}
          python={props.python}
          pythonLoaded={props.pythonLoaded}
          pythonError={props.pythonError}
          issueTestResult={issueTestResult}
        />

        : selectedMode === "Test" ?
        <XDTest 
          onEvent={props.onEvent}
          debug={props.debug}
          xdId={props.xdId}
          xdCases={props.xdCases}
          xdTestList={props.xdTestList}
          xddata={props.xddata}
          view={view}
          docInfo={props.docInfo}
          xdProblemDict={props.xdProblemDict}
          duckCon={props.duckCon}
          issueLatestUpdates={props.issueLatestUpdates}
          python={props.python}
          pythonLoaded={props.pythonLoaded}
          pythonError={props.pythonError}
          priorityDict={props.priorityDict}
          issueTestResult={issueTestResult}
        />

        : selectedMode === "Game Sim" ?
        <IHGame 
          onEvent={props.onEvent}
          setProp={props.setProp}
          xdId={props.xdId}
          xddata={props.xddata}
          debug={props.debug}
          docInfo={props.docInfo}
          
          // Edit
          ihGameList={props.ihGameList}
          selectedIHGame={props.selectedIHGame}
          screenGroupInfo={props.screenGroupInfo}
          ihGameMessage={props.ihGameMessage}

          // Play
          publishedIHGame={props.publishedIHGame}
          ihGamePlaySession={props.ihGamePlaySession}

          // Stat
          ihGameStatList={props.ihGameStatList}

          // Dependencies
          toDownloadUrl={props.toDownloadUrl}
          toWebDownloadUrl={props.toWebDownloadUrl}
          webviewReady={props.webviewReady}
          videoServerReady={props.videoServerReady}
          videoUrlPrefix={props.videoUrlPrefix}
          videoPathInfo={props.videoPathInfo}
        />

        : selectedMode === "Master" ?
        <Master
          onEvent={props.onEvent}
          xdId={props.xdId}
          schema={props.dpData}
          ihMaster={props.ihMaster}
          ihSchema={props.ihSchema}
        />
        
        :
        <></>}
      </div>
      
      {/* Modals */}
      <Modal
        open={addMode && props.xdedit}
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={(e: any) => setAddMode(false)}>
        <div style={{padding: "10px"}}>
          <div>Name</div>
          <Input
            size="mini" 
            fluid
            value={newXd.name} 
            disabled={!props.xdedit}
            onChange={(e: any) => { 
              if (!props.xdedit) return;
              setNewXd({name: e.target.value})
            }} 
          />
          <Button
            size="mini"
            color="green"
            disabled={!props.xdedit}
            onClick={(e: any) => {
              if (!props.xdedit) return;
              setAddMode(false);
              props.onEvent({message: "AddXD", params: newXd})
            }}>
            Add
          </Button>
        </div>
      </Modal>
      <Modal 
        open={nodeDetail && props.xdedit}
        closeOnDimmerClick={false}>
        <NodeDetail
          xdedit={props.xdedit}
          loc={loc}
          xddata={xddata}
          xdmaster={props.xdmaster}
          setProp={props.setProp}
          doneDetail={doneNodeDetail}
        />
      </Modal>
      <Modal 
        open={sectionDetail && props.xdedit}
        closeOnDimmerClick={false}
        size="tiny">
        <SectionDetail
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          sectionIndex={selectedSectionIndex}
          section={selectedSectionIndex !== null ? sections[selectedSectionIndex] : null}
          doneDetail={doneSectionDetail}
        />
      </Modal>
      <Modal 
        open={stepDetail && props.xdedit}
        closeOnDimmerClick={false}
        size="tiny">
        <StepDetail
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          loc={loc}
          xddata={xddata}
          setProp={props.setProp}
          doneDetail={doneRowDetail}
        />
      </Modal>
      <Modal 
        open={cellDetail}
        style={{minHeight: "80%"}}
        size="large"
        onClose={(e: any) => { 
          setCellDetail(false);
          loc?.step && setLoc({ step: loc.step});
        }}
        closeIcon={true}
        closeOnDimmerClick={true}
        >
        <StepEditor
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={props.xddata}
          trigger={props.trigger}
          docInfo={props.docInfo}
          editMode={"Role"}
          view={view}
          loc={loc}
          remove={remove}
          doneCellDetail={doneCellDetail}
        />
        {/* <CellDetail
          loc={loc}
          xddata={xddata}
          setProp={props.setProp}
          remove={remove}
          doneDetail={doneCellDetail}
        /> */}
      </Modal>
      <Modal
        open={props.xdSpecProgress?.length > 0 && props.xdedit}
        closeOnDimmerClick={true}
        onClose={() => props.setProp("xdSpecProgress", [])}
        size="tiny"
        >
        <div style={{height: "400px", overflowY: "auto", padding: "10px"}}>
          {props.xdSpecProgress.map((item: string, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </Modal>
      <Modal
        size="tiny"
        open={publishDialog ? true: false}
        onClose={() => {
          if (publishDialog?.finished) {
            setPublishDialog(null);
          }
        }}
        closeIcon={publishDialog?.finished ? true : false}>
        <div 
          style={{
            width: "100%", minHeight: "200px", padding: "10px",
            display: "flex", flexDirection: "column",
            justifyContent: "center", alignItems: "center"
          }}>
          <div 
            style={{
              flex: 1, display: "flex", flexDirection: "column",
              justifyContent: "center", alignItems: "center"
            }}>
            <div>
              {(publishDialog?.messages || []).map((message: string, index: number) => (
                <div 
                  style={{padding: "2px", textAlign: "center"}}
                  key={index}>
                  {message}
                </div>
              ))}
            </div>
          </div>
          {publishDialog?.finished &&
          <Button
            size="mini" color="red" compact
            onClick={() => setPublishDialog(null)}>
            Close
          </Button>}
        </div>
      </Modal>
    </div>
  )
}

const Mode = (props: any) => {
  return(
    <div 
      style={{
        padding: "0 5px 0 5px", 
        margin: "0 1px 0 1px",
        backgroundColor: props.selectedMode == props.name ? "cyan" : "#eeeeee",
        cursor: "pointer"
      }}
      onClick={(e: any) => props.setSelectedMode(props.name)}
      >
      {props.name}
    </div>
  )
}

export default XD;

// : selectedMode === "Json" ?
// <XDJson 
//   xddata={props.xddata}
// />

// : selectedMode === "Test Scene" ?
// <XDScene
//   xdmaster={props.xdmaster}
//   xddata={props.xddata}
//   view={view}
// />

// : selectedMode === "Spec" ?
// <XDSpec
//   onEvent={props.onEvent}
//   setProp={props.setProp}
//   xdmaster={props.xdmaster}
//   xddata={props.xddata}
//   xdSpecProgress={props.xdSpecProgress}
// />

// : selectedMode === "Slide" ?
// <XDSlide 
//   xddata={xddata}
//   view={view}
// />