import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from "firebase/compat/app";
import { 
  StaffProfileData, StaffDict, MonthDetailData, DividendDict, LetterDict,
} from './TypeDef';
import { previousYm } from './Utils';
import { tableFromJSON } from 'apache-arrow';


export type State = {
  staffDict?: StaffDict,
  staffProfile?: StaffProfileData,
  monthDetail?: MonthDetailData,
  dividendDict?: DividendDict,
  letterDict?: LetterDict,
  triggerStaff?: boolean,
}

export const StateInitial = {
  staffDict: {},
  staffProfile: {},
  monthDetail: {},
  letterDict: {},
  triggerStaff: false,
}

export type Event =
  { message: "GetStaffData", params: {} }
  | { message: "UpdateStaff", 
      params: {id: string, status?: string} }
  | { message: "EditStaff", 
      params: {username: string, name: number, start: string, role: string, add: boolean} }
  | { message: "UpdateStaffDetail", 
      params: {id: any, ym: string, detailItem: any} }
  | { message: "AddStaffDetail", 
      params: {item: any, month: string} }
  | { message: "SetDividend", 
      params: {ym: string, value: string} }
  | { message: "SaveStaffLetter", 
      params: {ym: string, letter: {[key: string]: string}} }
  | { message: "AddMonthDetail", 
      params: {ym: string} }

export type Data = {
  duckCon?: any,
}

export const DataInitial = {
}
  
type Handler = WasmHandler<State, Event>

export const GetStaffData: Handler = async (controller, params) => {
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;

  const data = (await (dbadmin.collection("summary").doc("staffData").get())).data()?.data;
  if (!data) return;
  const dict = Object.fromEntries(data.map((item: any) => ([item.id, item])));
  // await MigrateData(controller, {dict});
  let staffProfile = (await (dbadmin.collection("cache").doc("StaffProfile").get())).data() || {};
  let monthDetail = (await (dbadmin.collection("cache").doc("MonthDetail").get())).data() || {};
  const dividendDict = (await (dbadmin.collection("summary").doc("dividend").get())).data();
  const letterDict = (await (dbadmin.collection("summary").doc("letter").get())).data();

  let staffMonthDetail = Object.entries(monthDetail).flatMap((detail: any) =>
    Object.entries(detail[1]).map((staff: any) => (
      {
        ym: detail[0],
        staff: staff[0],
        role: staff[1]?.role,
        staffId: staff[1]?.staffId,
        base: staff[1]?.components?.base || 0,
        bonus: staff[1]?.components?.bonus || 0,
        stock: staff[1]?.components?.stock || 0,
        dividend: staff[1]?.components?.dividend || 0,
      }
    ))
  );
  let staffMonthDetailLong = 
    Object.entries(monthDetail).flatMap(([ym, ymItem]: any) =>
      Object.entries(ymItem).flatMap(([staff, staffItem]: any) => (
        Object.entries(staffItem.components).flatMap(([category, categoryValue]: any) => (
          {
            ym:       ym,
            staff:    staff,
            role:     staffItem?.role,
            category: category,
            value:    categoryValue,
          })))));
  let { duckCon } = controller.data;
  try { 
    await duckCon.query(`drop table if exists staff_month_detail;`)
    await duckCon.query(`drop table if exists staff_month_detail_long;`)
  } catch {}
  try {
      await duckCon.insertArrowTable(tableFromJSON(staffMonthDetail), 
          {name: "staff_month_detail", schema: "main", create: true});
      await duckCon.insertArrowTable(tableFromJSON(staffMonthDetailLong), 
          {name: "staff_month_detail_long", schema: "main", create: true});
  } catch (e: any) { console.log(e); };

  // Sync state
  controller.setState({
    staffDict: dict, 
    dividendDict, 
    letterDict,
    staffProfile,
    monthDetail,
  });
}

export const EditStaff: Handler = async (controller, params) => {
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;

  let {username, name, start, role, employeeId, add} = params;
  if (!username) return;
  let checkexisting = await dbadmin.collection("StaffProfile").doc(username).get()
  if (add && checkexisting.exists) {
    console.log("Staff with that username already exists. Abort.")
    return
  }

  if (add) {
    let newData = {
      name: name || "",
      email: `${username}@thevcgroup.com`,
      employeeId: employeeId,
      start: start || "",
      end: start,
      role: role,
      status: "Active"
    }
    await dbadmin.collection("StaffProfile").doc(username).set(newData);
    await dbadmin.collection("cache").doc("StaffProfile").update({[username]: newData});
    await GetStaffData(controller, {});
  } else {
    let editedData = {
      ...checkexisting.data(),
      name, start, role, employeeId
    }
    await dbadmin.collection("StaffProfile").doc(username).set(editedData);
    await dbadmin.collection("cache").doc("StaffProfile").update({[username]: editedData});
    await GetStaffData(controller, {});
  }
}

export const UpdateStaff: Handler = async (controller, params) => {
  let {id, ...data} = params;
  if (!id) return;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;

  let oldData = controller.getState().staffProfile?.[id];
  if (!oldData) return;
  
  await dbadmin.collection("StaffProfile").doc(id).update(data);
  await dbadmin.collection("cache").doc("StaffProfile")
    .update({ [id]: { ...oldData, ...data }});
  controller.setProp(`staffProfile.${id}`, {...oldData, ...data});
}

export const UpdateStaffDetail: Handler = async (controller, params) => {
  let {staffId, ym, detailItem} = params;
  if (!staffId || !ym || !detailItem) return;
  const state = controller.getState();
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;
  detailItem.ym = ym;
  detailItem.staffId = staffId;
  if (!detailItem?.role || detailItem.role == "no role")
    detailItem.role = state.staffProfile?.[staffId]?.role || "no role"
  detailItem.components = {
    base: 0, bonus: 0, dividend: 0, stock: 0,
    ...(detailItem?.components || {})
  }
  await dbadmin.collection("MonthDetail").doc(ym)
    .update({[staffId]: detailItem});
  await dbadmin.collection("cache").doc("MonthDetail")
    .update({[`${ym}.${staffId}`]: detailItem});
  controller.setProp(`monthDetail.${ym}.${staffId}`, detailItem, 
    () => controller.setState({triggerStaff: !controller.getState().triggerStaff}));
}

export const AddStaffDetail: Handler = async (controller, params) => {
  if (!params.item || !params.ym) return;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;
  
  let {item, ym} = params
  let {id, base, last, role, stock, ...data} = item;
  const foundIndex = (data?.detail || []).findIndex((item: any) => item.ym === ym);
  if (foundIndex >= 0) return console.log("That detail is already present");
  data.detail.push({
    ym: ym,
    role: item.role,
    components: {
      base: item.base,
      bonus: 0,
      stock: item.stock,
      dividend: 0
    }
  });
  await dbadmin.collection("compensation").doc(id)
    .update({detail: data.detail, end: ym});
  UpdateSummaryDoc(controller, {id, detail: data.detail, end: ym});
}

export const SetDividend: Handler = async (controller, params) => {
  let {ym, value} = params;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  let valueNum = Number(value);
  if (!ym || !value || value === "" || Number.isNaN(valueNum) || !dbadmin) return;
  
  // Save in dividend doc
  dbadmin.collection("summary").doc("dividend").update({[ym]: valueNum});
  
  // Need to rewrite this part to update to MonthDetail instead of compensation/staffData
  // let staffDict: StaffDict = controller.getState()?.staffDict || {};

  // // Update staffDict & add to batch
  // var batch = dbadmin.batch();
  // for (const id in staffDict) {
  //   if (staffDict[id].status === "Inactive") continue;
  //   let ymIndex = staffDict[id].detail.findIndex((item) => item.ym === ym);
  //   if (ymIndex >=0 && staffDict[id].detail[ymIndex].components.stock > 0) {
  //     staffDict[id].detail[ymIndex].components.dividend = 
  //       staffDict[id].detail[ymIndex].components.stock * valueNum;
  //     batch.update(
  //       dbadmin.collection("compensation").doc(id), 
  //       {detail: staffDict[id].detail}
  //     );
  //   }
  // }

  // // Sync state  
  // controller.setState({staffDict: Object.assign({}, staffDict)});

  // // Save in invididual doc
  // await batch.commit();

  // // Save in summary doc
  // let staffData = Object.values(staffDict);
  // dbadmin.collection("summary")
  //   .doc("staffData")
  //   .update({data: staffData});
}

export const SaveStaffLetter: Handler = async (controller, params) => {
  let {ym, letter} = params;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!ym || !letter || letter === "" || !dbadmin) return;
  
  // Save in dividend doc
  dbadmin.collection("summary").doc("letter").set({[ym]: letter});

  // Sync state  
  const letterDict = controller.getState().letterDict || {};
  letterDict[ym] = letter;
  controller.setState({letterDict});
}

export const AddMonthDetail: Handler = async (controller, params) => {
  let { ym } = params;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!ym || !dbadmin) return;
  
  let ymBefore = previousYm(ym);
  let result = await dbadmin.collection("MonthDetail").doc(ym).get();
  if (result.exists) return console.log(`${ym} already exists. Abort.`);
  result = await dbadmin.collection("MonthDetail").doc(ymBefore).get();
  if (!result.exists) return console.log(`${ymBefore} not exists. Abort.`);  
  let newData = Object.fromEntries(
    Object.entries(result.data() || {})
      .map((entry: [string, any]) => (
        [ entry[0],
          {
            ...entry[1],
            ym,
            components: {
              ...entry[1].components,
              dividend: 0,
              bonus: 0,
            }
          }])));
  await dbadmin.collection("MonthDetail").doc(ym).set(newData);
  await dbadmin.collection("cache").doc("MonthDetail").update({[`${ym}`]: newData});
  controller.setProp(`monthDetail.${ym}`, newData, 
    () => controller.setState({triggerStaff: !controller.getState().triggerStaff}));
}

// To be deprecated ----------------------------------------------------------------
export const UpdateSummaryDoc: Handler = async (controller, params) => {
  let {id, ...data} = params;
  if (!id) return;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;

  let staffDict: StaffDict = controller.getState()?.staffDict || {};
  staffDict[id] = {...staffDict[id], id, ...data}
  let staffData = Object.values(staffDict);
  dbadmin.collection("summary")
    .doc("staffData")
    .update({data: staffData});

  // Sync state
  controller.setState({staffDict: Object.assign({}, staffDict)});
}

const downloadData = (dict: any) => {
  const link = document.createElement('a');
  link.download = "compensation6.json";
  link.href = URL.createObjectURL(new Blob([JSON.stringify(dict, null, 1)], 
    {type: "application/json"}));
  link.click();
}

const MigrateData: Handler = async (controller, params) => {
  let {dict} = params;
  const dbadmin = (controller as any)?.dbadmin as app.firestore.Firestore;
  if (!dbadmin) return;

  let monthDetail = 
    Object.entries(dict)
      .flatMap((staff: [string, any]) => 
        staff[1].detail.map((ym: any) => (
          { ...ym, staffId: staff[0].split("@")[0] }
        )))
      .reduce((acc: any, cur: any) => {
        if (!Object.keys(acc).includes(cur.ym)) {
          acc[cur.ym] = {}
        }
        acc[cur.ym][cur.staffId] = cur;
        return acc;
      }, {})
  for (const staffId in dict) {
    await dbadmin.collection("StaffProfile").doc(staffId.split("@")[0]).set({
      email: dict[staffId].email,
      start: dict[staffId].start,
      end: dict[staffId].end,
      name: dict[staffId].name || staffId.split("@")[0],
      status: dict[staffId].status || "Active",
    })
  }
  for (const ym in monthDetail) {
    await dbadmin.collection("MonthDetail").doc(ym).set(monthDetail[ym])
  }
  let StaffProfile = Object.fromEntries((await dbadmin.collection("StaffProfile").get())
    .docs.map((doc: any) => ([doc.id, doc.data()])));
  await dbadmin.collection("cache").doc("StaffProfile").set(StaffProfile);
  let MonthDetail = Object.fromEntries((await dbadmin.collection("MonthDetail").get())
    .docs.map((doc: any) => ([doc.id, doc.data()])));
  await dbadmin.collection("cache").doc("MonthDetail").set(MonthDetail);
  console.log("done----------------------------------------")
}

// Download a copy
// downloadData(dict);

// Get data from local
// const dict: StaffDict = await (await fetch("data/compensation4.json")).json();