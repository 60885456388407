import React, { useState } from 'react';
// import { app, db, functions, ui } from '../react-lib/frameworks/Firebase'
import { Button, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import ISMORController from './ISMORController';
import { ISMORState, ISMORStateInitial } from './ISMORInterface';

// ismor-lib
import Redmine from './ismor-lib/apps/REDMINE/Redmine';
import CreateUI from './ismor-lib/apps/UIGEN/CreateUI';
import CreateUIv2 from './ismor-lib/apps/UIGEN/CreateUIv2';
import DP from './ismor-lib/apps/DP/DP';
import Pic2UI from './ismor-lib/apps/UIGEN/Pic2UI';
import Sheet from './ismor-lib/apps/LAB/Sheet';
import Video from './ismor-lib/apps/IHGAME/Video';
import Camera from './ismor-lib/apps/LAB/Camera';
import TestComponent from './ismor-lib/apps/UIGEN/TestComponent';
import MyBPlus from './ismor-lib/apps/LAB/MyBPlus';
import SetProp from './react-lib/frameworks/SetProp';
import FirebaseLoginForm from './react-lib/frameworks/FirebaseLoginForm';
import PDF from './ismor-lib/apps/PDF/PDF';
import Stat from './ismor-lib/apps/STAT/Stat';
import Story from './ismor-lib/apps/STORY/Story';
import Performance from './ismor-lib/apps/MONITOR/Performance';
import Perf from './ismor-lib/apps/PERF/Perf';
import Encounter from './ismor-lib/apps/ENCOUNTER/Encounter';
import Score from './ismor-lib/apps/SCORE/Score';
import Contest from './ismor-lib/apps/SCORE/Contest';
import XD from "./ismor-lib/apps/XD2/XD";
// import Master from "./ismor-lib/apps/LAB/Master";
import Web from "./ismor-lib/apps/WEB/Web";
import Client from "./ismor-lib/apps/CLIENT/Client";
import Staff from "./ismor-lib/appsadmin/STAFF/Staff";
import Keep from "./ismor-lib/apps/CLIENT/Keep";
import NotiIcon from "./ismor-lib/apps/REDMINE/NotiIcon";
import IHGamePlay from "ismor-lib/apps/IHGAME/IHGamePlay";
import CP from "ismor-lib/apps/CLIENT/CP";
import LN from "ismor-lib/apps/LN/LN";
import MED from 'ismor-lib/apps/MED/MED';
import HL from "ismor-lib/apps/HL/HL";
import HZ from "ismor-lib/apps/HZ/HZ";
import Master from 'ismor-lib/apps/DP/Master';
// import Claim from './ismor-lib/apps/DP/Claim'
// import Upload from './ismor-lib/apps/LAB/Upload'
// import Pic from "./ismor-lib/apps/LAB/Pic";

declare global {
  interface Window { pywebview: any, setState: any, onEventGolang: any }
}
window.setState = window.setState || {};

class App extends React.Component<any, ISMORState> {

  controller: ISMORController;
  state: ISMORState;

  constructor(props: any) {
    super(props);
    this.controller = new ISMORController(
      () => { return this.state },
      (state: ISMORState, callback: any) => { this.setState(state, callback) },
      window,
      false //<<<----- Admin flag ----------------------------------
    )
    this.controller.setProp = SetProp(this, "");
    const urlParams = new URLSearchParams(window.location.search);
    window.history.replaceState(null, "", window.location.pathname);
    this.state = {
      ...ISMORStateInitial,
      urlParams: urlParams,
      webviewReady: false,  
      pywebviewData: 0,
      copyToken: false,
      ...(urlParams.get('tab') ? {tab: urlParams.get('tab')!} : {})
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount = async () => {
    this.controller.handleEvent({ message: "ISMORDidMount", params: {} });
    if (window.pywebview)
      this.setWebviewReady()
    else if (window.onEventGolang)
      this.setWebviewReadyGolang()
    else
      window.addEventListener("pywebviewready", this.setWebviewReady);  
  }

  setWebviewReady = async () => {
    this.setState({webviewReady: true});
    window.setState = this.setState.bind(this);
  }

  setWebviewReadyGolang = async () => {
    window.pywebview = { 
      api: { 
        onEvent: (event: {message: string, params: any}) => { 
          window.onEventGolang(event.message, event.params);
        }
      }
    };
    this.setState({webviewReady: true});
    window.setState = this.setState.bind(this);
  }

  setTab = (tab: string) => {
    this.setState({ tab: tab, mobileMenu: false });
    // console.log(tab);
    // if (["Py", "Video"].includes(tab)) return; // Skip save preference if selecting Py
    this.controller.handleEvent({ 
      message: "SavePreferences", 
      params: this.state.webviewReady ? { plustab: tab } 
              : this.controller.ismoradmin ? { admintab: tab } 
              : { tab: tab } 
    });
  }

  render = () => {
    const hasperms = (name: string) => this.state.permissions?.app?.includes(name);
    const ismobile = this.state.mobileScreen;

    let menuList: any[];

    if (this.state.webviewReady) {
      menuList = [   
        ["PM",          true],
        ["XD",          hasperms("xd") && !ismobile],

        // Inactive ---------------------------------------------------------------
        ["Video",       hasperms("debug") && this.state.webviewReady && !ismobile && false],
        ["Py",          hasperms("debug") && this.state.webviewReady && !ismobile && false],
      ]
      .filter((item: any) => item[1])
      .map((item: any) => item[0]);
    } else if (!this.controller.ismoradmin) {
      // Normal menu -------------------------------------------------------------
      menuList = [   
        // All -------------------------------------------------------------------
        ["PM",          true],
        
        // Consult ---------------------------------------------------------------
        ["XD",          hasperms("xd") && !ismobile],
        ["UI",          hasperms("ui") && !ismobile],
        ["PDF",         hasperms("pdf") && !ismobile],
        ["GS",          !ismobile],
        ["CP",          hasperms("cp") && !ismobile],
        ["DP",          (hasperms("ui") || hasperms("xd")) && !ismobile],
        ["KP",          hasperms("debug") && !ismobile],
        
        // ------------------------------------------------------------------------
        ["|",           hasperms("deal") && !ismobile],

        // Learning
        ["LN",          hasperms("debug") && !ismobile],
        ["MT",          hasperms("debug") && !ismobile],
        ["MD",          hasperms("debug") && !ismobile],
        ["HZ",          hasperms("debug") && !ismobile],
        ["IM",          hasperms("debug") && !ismobile],
                
        // ------------------------------------------------------------------------
        ["|",           hasperms("debug") && !ismobile],

        // Executive --------------------------------------------------------------
        ["AC",          hasperms("deal") && !ismobile],
        ["HL",          hasperms("debug") && !ismobile],
        ["MW",          hasperms("debug") && !ismobile],

        // Dev --------------------------------------------------------------------
        ["|",           hasperms("debug") && !ismobile],

        // Inactive ---------------------------------------------------------------
        ["ST",          hasperms("debug") && false],
        ["Camera",      hasperms("debug") && false],
        ["Script",      hasperms("debug") && !ismobile && false],
        ["API",         hasperms("debug") && !ismobile && false],
        ["Master",      hasperms("debug") && !ismobile && false],
        ["MyBPlus",     hasperms("debug") && !ismobile && false],
        ["Pic2UI",      hasperms("debug") && !ismobile && false],
        ["Sheet",       hasperms("debug") && !ismobile && false],
        ["Test",        hasperms("debug") && !ismobile && false],
        ["UIv1",        hasperms("debug") && !ismobile && false],
        ["Contest",     hasperms("contest") && !ismobile && false],
        ["Encounter",   hasperms("encounter") && !ismobile && false],
        ["Perf",        false],
      ]
      .filter((item: any) => item[1])
      .map((item: any) => item[0]);
    } else {
      // Admin menu --------------------------------------------------------------
      menuList = [   
        ["Staff",       !ismobile],
      ]
      .filter((item: any) => item[1])
      .map((item: any) => item[0]);
    }

    return(
    <div style={{height: "100%" }}>
      {!this.state.authChecked ?
      <div 
        style={{
          height: "100vh", width: "100%", display: "flex", 
          justifyContent: "center", alignItems: "center",
          fontSize: "2rem", color: "#81C32E"
        }}>
        Checking login status
      </div>
      
      : !this.state.loggedin ? 
      <FirebaseLoginForm 
        controller={this.controller} 
        webviewReady={this.state.webviewReady}
      /> 
      
      :
      <div style={{textAlign: "left", height: "95%", width: "100%"}}>
        <div 
          style={{
            width: '100%', display: 'flex', fontSize: '14px',
            justifyContent: 'flex-start', alignItems: "center",
            cursor: 'pointer', borderBottom: 'solid grey 0.5px',
            backgroundColor: this.controller.ismoradmin ? "orange": "white",
          }}>
            
          {/* Mobile menu */}
          {this.state.mobileScreen &&
          <div>
            <Icon 
              name="align justify" 
              size="large" 
              onClick={(e: any) => {
                this.setState({
                  mobileMenu: !this.state.mobileMenu
                })
              }}
            />
            {this.state.mobileMenu &&
            <div 
              style={{ 
                position: "absolute", top: 40, left: 0, padding: "5px",
                borderRight: "solid black 1px", borderBottom: "solid black 1px", 
                backgroundColor: "white", zIndex: 999
              }}>
              {menuList.map((name: string) => 
              <MenuItem tab={this.state.tab} setTab={this.setTab} name={name} />
              )}
            </div>}
          </div>
          }
          
          {/* Site Logo */}
          <div 
            style={{
              color: this.controller.ismoradmin ? "cyan" : "#81C32E", 
              padding: '5px', fontWeight: 'bolder', 
              fontSize: '18px', marginRight: "20px" 
            }}>
            IsMor
          </div>

          {/* Desktop menu */}
          {!this.state.mobileScreen && 
            menuList.map((name: string, index: number) => 
            <MenuItem key={index} tab={this.state.tab} setTab={this.setTab} name={name} />
          )}

          <div style={{flex: 1}}>&nbsp;</div>

          {/* Notifications */}
          {hasperms("notitest") &&
          <NotiIcon 
            onEvent={this.controller.handleEvent}
            notifications={this.state.notifications}
            redmineUser={this.state.redmineUser}
          />
          }
          
          {/* Download App && Copy token */}
          <div
            style={{ 
              cursor: "pointer", 
              backgroundColor: this.state.copyToken ? "#81C32E" : "white", 
              border: "solid #cccccc 1px",
              padding: "2px", 
              margin: "2px", fontSize: "0.8rem"
            }}
            onClick={(e: any) => { 
              this.controller.handleEvent({
                message: "CopyFirebaseToken",
                params: {}
              })
              this.setState({ copyToken: true })
              setTimeout(() => {
                this.setState({ copyToken: false })
              }, 200)
            }}>
            Token
          </div>
          <Icon name="plus square" 
            color="blue"
            size="big"
            onClick={(e: any) => {
              this.controller.handleEvent({
                message: "DownloadApp",
                params: {}
              });
            }}
          />
          {/* Logout */}
          <div>
            <Button 
              color="red"
              size="mini" 
              onClick={(e) => { this.controller.handleEvent({ message: "Logout", params: {} }) }}>
              Logout
            </Button>
          </div>
        </div>

        <div style={{ height: "95vh", ...(this.state.mobileScreen ? {} : {overflowY: "auto"})}} >
          {this.state.tab === "PM" ?
          <Redmine 
            setProp={this.controller.setProp}
            hasperms={hasperms}
            redmineSub={this.state.redmineSub}
            redmineUser={this.state.redmineUser}
            mobileScreen={this.state.mobileScreen}
            redmineLoaded={this.state.redmineLoaded}
            chartData={this.state.chartData}
            byReleaseData={this.state.byReleaseData}
            byDateData={this.state.byDateData}
            byDateDataResolved={this.state.byDateDataResolved}
            byDateDataCreate={this.state.byDateDataCreate}
            byDateDataUpdate={this.state.byDateDataUpdate}
            byProjectData={this.state.byProjectData}
            byStatusData={this.state.byStatusData}
            byClassifyData={this.state.byClassifyData}
            performanceData={this.state.performanceData}
            onEvent={this.controller.handleEvent}
            functions={this.controller.functions} 
            notifications={this.state.notifications}
            Perf={<Perf
              onEvent={this.controller.handleEvent}
              setProp={this.controller.setProp}
              mobileScreen={this.state.mobileScreen}
              perfData={this.state.perfData}
              supervise={
                [this.controller.user.email]
                .concat(this.state.permissions?.supervise || [])
              }
              selectedUser={this.state.selectedUser}
            />}
            supervise={this.state.permissions?.supervise || []}
            priorityDict={this.state.priorityDict}
            dealOngoing={this.state.dealOngoing}
            issueLatestUpdates={this.state.issueLatestUpdates}
            python={this.controller.data.python}
            pythonLoaded={this.state.pythonLoaded}
            pythonError={this.state.pythonError}
            bokehLoaded={this.state.bokehLoaded}
            duck={this.controller.data.duck}
            duckCon={this.controller.data.duckCon}
            duckLoaded={this.state.duckLoaded}
            assignedIssues={this.state.assignedIssues}
            performanceAll={this.state.performanceAll}
            performanceAllRaw={this.state.performanceAllRaw}
            performanceForUserRaw={this.state.performanceForUserRaw}
            performanceForUser={this.state.performanceForUser}
            devList={this.state.devList}
            performanceDevRaw={this.state.performanceDevRaw}
            activeIssues={this.state.activeIssues}
            redmineProjectList={this.state.redmineProjectList}
            projectIssues={this.state.projectIssues}
            xdmaster={this.state.xdmaster}
            xdCases={this.state.xdCases}
          />:

          this.state.tab === "UIv1" ?
          <CreateUI 
            onEvent={this.controller.handleEvent}
            loggedin={this.state.loggedin}
            db={this.controller.db} 
            fs={this.state.fs}
            user={this.controller.user} />:

          this.state.tab === "UI" ?
          <CreateUIv2 
            onEvent={this.controller.handleEvent}
            loggedin={this.state.loggedin}
            db={this.controller.db} 
            screenData={this.state.screenData}
            user={this.controller.user} 
            editedElementId={this.state.editedElementId}
            projectScreens={this.state.projectScreens}
            screenId={this.state.screenId}
            setProp={this.controller.setProp}
            openGridCanvas={this.state.openGridCanvas}
            divGridCells={this.state.divGridCells}
            draggedCellIndex={this.state.draggedCellIndex}
            selectedProject={this.state.selectedProject}
          />:

          this.state.tab === "Encounter" ?
          <Encounter 
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
          />:

          this.state.tab === "API" ?
          <Performance
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            apiAnalysisData={this.state.apiAnalysisData}
          />:

          this.state.tab === "Score" ?
          <Score
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            scoring={this.state.scoring}
            hn={this.state.hn}
          />:

          this.state.tab === "Contest" ?
          <Contest
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            hnList={this.state.hnList}
            scoringList={this.state.scoringList}
          />:

          this.state.tab === "Perf" ?
          <Perf
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            mobileScreen={this.state.mobileScreen}
            perfData={this.state.perfData}
            supervise={
              [this.controller.user.email]
              .concat(this.state.permissions?.supervise || [])
            }
            selectedUser={this.state.selectedUser}
          />:

          this.state.tab === "PDF" ?
          <PDF
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            formRefList={this.state.formRefList}
            selectedFormRef={this.state.selectedFormRef}
            formDef={this.state.formDef}
            pdfSrc={this.state.pdfSrc}
            setFormDef={SetProp(this, "formDef")}
          />:

          this.state.tab === "XD" ?
          <XD
            // Permissions & Utils
            xdedit={hasperms("xdedit")}
            user={this.controller.user}
            debug={hasperms("debug")}
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            trigger={this.state.trigger}
            urlParams={this.state.urlParams}
            xdSpecProgress={this.state.xdSpecProgress}

            // Core data
            xddata={this.state.xddata}
            xdmaster={this.state.xdmaster}
            xdId={this.state.xdId}
            docInfo={this.state.docInfo}
            
            // Case & Test
            xdCases={this.state.xdCases}
            priorityDict={this.state.priorityDict}
            dealOngoing={this.state.dealOngoing}
            issueLatestUpdates={this.state.issueLatestUpdates}
            xdProblemDict={this.state.xdProblemDict}
            xdTestList={this.state.xdTestList}
            
            // Journey
            xdJourneyDataState={this.state.xdJourneyDataState}
            toDownloadUrl={(url: string) => 
              this.controller.storage.refFromURL(url).getDownloadURL()}
            
            // Game
            ihGameList={this.state.ihGameList}
            selectedIHGame={this.state.selectedIHGame}
            screenGroupInfo={this.state.screenGroupInfo}
            ihGameMessage={this.state.ihGameMessage}
            publishedIHGame={this.state.publishedIHGame}
            ihGamePlaySession={this.state.ihGamePlaySession}
            toWebDownloadUrl={(url: string) => 
              this.controller.storageweb.refFromURL(url).getDownloadURL()}
            ihGameStatList={this.state.ihGameStatList}
            clientUserDict={this.state.clientUserDict || new Map()}

            // Master
            dpData={this.state.dpData}
            ihMaster={this.state.ihMaster}
            ihSchema={this.state.ihSchema}

            // Dependencies
            duck={this.controller.data.duck}
            duckCon={this.controller.data.duckCon}
            duckLoaded={this.state.duckLoaded}
            python={this.controller.data.python}
            pythonLoaded={this.state.pythonLoaded}
            pythonError={this.state.pythonError}
            webviewReady={this.state.webviewReady}
            videoServerReady={this.state.videoServerReady}
            videoUrlPrefix={this.state.videoUrlPrefix}
            videoPathInfo={this.state.videoPathInfo}
          />:

          this.state.tab === "ST" ?
          <Stat
            onEvent={this.controller.handleEvent}
            mobileScreen={this.state.mobileScreen}
            ihUserProfile={this.state.ihUserProfile}
            statList={this.state.statList}
          />:

          this.state.tab === "MW" ?
          <Web 
            onEvent={this.controller.handleEvent}
            webData={this.state.webData}
            webLoggedin={this.state.webLoggedin}
          />:

          this.state.tab === "MD" ?
          <MED
            onEvent={this.controller.handleEvent}
            medList={this.state.medList}
            medItem={this.state.medItem}
          />:

          this.state.tab === "HL" ?
          <HL
            onEvent={this.controller.handleEvent}
            hospitalList={this.state.hospitalList}
          />:

          this.state.tab === "HZ" ?
          <HZ
            onEvent={this.controller.handleEvent}
            hzList={this.state.hzList}
          />:

          this.state.tab === "AC" ?
          <Client
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            getDocumentUrl={(url: string) => 
              this.controller.storage.refFromURL(url).getDownloadURL()}
            webviewReady={this.state.webviewReady}
            clientDict={this.state.clientDict}
            clientDeal={this.state.clientDeal}
            webviewMessages={this.state.webviewMessages}
            pdfExtract={this.state.pdfExtract}
            python={this.controller.data.python}
            pythonLoaded={this.state.pythonLoaded}
            pythonError={this.state.pythonError}
            bokehLoaded={this.state.bokehLoaded}
            pin={this.state.preferences?.pin}
          />:

          this.state.tab === "IM" ?
          <Story
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            selectedStory={this.state.selectedStory}
            storyMaster={this.state.storyMaster}
            imageSearchList={this.state.imageSearchList}
          />:

          this.state.tab === "MT" ?
          <Master
            onEvent={this.controller.handleEvent}
            xdId={"kyffv7y97BOxhrsagr4f"}
            schema={this.state.dpData}
            ihMaster={this.state.ihMaster}
            ihSchema={this.state.ihSchema}
          />:
          
          this.state.tab === "DP" ?
          <DP    
            onEvent={this.controller.handleEvent}
            debug={hasperms("debug")}
            bokehLoaded={this.state.bokehLoaded}
            duck={this.controller.data.duck}
            duckCon={this.controller.data.duckCon}
            duckLoaded={this.state.duckLoaded}
            dpDataName={this.state.dpDataName}
            dpDataUrl={this.state.dpDataUrl}
            dpData={this.state.dpData}
          />:

          this.state.tab === "Sheet" ?
          <Sheet 
            loggedin={this.state.loggedin} />:

          this.state.tab === "Video" ?
          <Video
            controller={this.controller}
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            videoServerReady={this.state.videoServerReady}
            videoPathInfo={this.state.videoPathInfo}
            videoData={this.state.videoData}
            videoUrlPrefix={this.state.videoUrlPrefix}
            videoBeingGenerated={this.state.videoBeingGenerated}
            videoMessage={this.state.videoMessage}
          />:

          this.state.tab === "Pic2UI" ?
          <Pic2UI 
            onEvent={this.controller.handleEvent}
          />:

          this.state.tab === "Camera" ?
          <Camera />:

          this.state.tab === "Test" ?
          <TestComponent 
            screenPropsDefault={this.state.screenData?.screenPropsDefault || {}}
          />:

          this.state.tab === "MyBPlus" ?
          <MyBPlus 
            myBPlusStats={this.state.myBPlusStats}
            onEvent={this.controller.handleEvent}
          />:

          this.state.tab === "Py" && this.state.webviewReady ?
          <div>
            <div>{`pywebviewData: ${this.state.pywebviewData}`}</div>
            <Button
              size="mini"
              color={this.state.pywebviewData === 0 ? "red" : "green"}
              onClick={async (e: any) => {
                  await window.pywebview.api.onEvent({message: "GetData", params: {test: 1}});
              }}>
              Get Data
            </Button>
          </div>:

          this.state.tab === "Staff" && this.controller.ismoradmin && hasperms("staff") ?
          <Staff
            duckCon={this.controller.data.duckCon}
            duckLoaded={this.state.duckLoaded}
            adminLoggedin={this.state.adminLoggedin}
            staffplus={hasperms("staffplus")}
            onEvent={this.controller.handleEvent}
            staffDict={this.state.staffDict}
            dividendDict={this.state.dividendDict}
            letterDict={this.state.letterDict}
            staffProfile={this.state.staffProfile}
            monthDetail={this.state.monthDetail}
            triggerStaff={this.state.triggerStaff}
          />:

          this.state.tab === "KP" ?
          <Keep
            onEvent={this.controller.handleEvent}
            keepList={this.state.keepList}
            getDocumentUrl={(url: string) => 
              this.controller.storage.refFromURL(url).getDownloadURL()}
          />:

          this.state.tab === "GS" ?
          <IHGamePlay 
            onEvent={this.controller.handleEvent}
            publishedIHGame={this.state.publishedIHGame}
            ihGamePlaySession={this.state.ihGamePlaySession}
            ihGameList={this.state.ihGameList}
            xdmaster={this.state.xdmaster}
          />:

          this.state.tab === "LN" ?
          <LN
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            preferences={this.state.preferences}
            lessonList={this.state.lessonList}
            lessonId={this.state.lessonId}
            lessonData={this.state.lessonData}
            toDownloadUrl={(url: string) => 
              this.controller.storage.refFromURL(url).getDownloadURL()}
          />:

          this.state.tab === "CP" ?
          <CP
            onEvent={this.controller.handleEvent}
            debug={hasperms("debug")}
            clientUserDict={this.state.clientUserDict || new Map()}
            clientContactDict={this.state.clientContactDict || new Map()}
            clientDict={this.state.clientDict || {}}
            clientDeal={this.state.clientDeal || {}}
            xdmaster={this.state.xdmaster || {}}
            ihGameList={this.state.ihGameList || []}
          />:

          <></>
          }
        </div>
        {/* <Tab panes={panes} style={{height: "90%"}}/> */}
      </div>   
      }
    </div>
    )    
  }
}

const MenuItem = ({ tab, setTab, name} : {tab?: string, setTab: (s:string) => void, name: string}) => (
  <div
    style={{
      padding: '0 10px 0 10px', 
      fontWeight: tab === name ? "bolder" : 'lighter',
      color: tab === name ? "blue" : "black"
    }}
    onClick={(e) => setTab(name)}
  >{name}</div>
)

export default App;

// this.state.tab === "Script" ?
// <Claim 
//   onEvent={this.controller.handleEvent}
//   pythonLoaded={this.state.pythonLoaded} 
//   // pythonResults={props.pythonResults}
//   // pythonError={props.pythonError}
//   claimData={this.state.claimData}
//   sap_fileLoading={this.state.sap_fileLoading}
//   mapping_fileLoading={this.state.mapping_fileLoading}
//   SAPClearing={this.state.SAPClearing}
//   SAPClearingError={this.state.SAPClearingError}
//   // PythonPreload={this.state.PythonPreload}
//   // python={props.python} 
//   // db={props.db} 
//   // user={props.user} 
//   />:
// this.state.tab === "Master" ?
// <Master
//   onEvent={this.controller.handleEvent}
//   SQL={this.controller.data.SQL}
//   masterDb={this.controller.data.masterDb}
//   queryData={this.state.querydata}
//   tableByLot={this.state.tableByLot}
//   maxLot={this.state.maxLot}
// />:

// this.state.tab === "Pic" ?
// <Pic
// />:
