import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import XDCaseFormAddorEditProblem from "./XDCaseFormAddorEditProblem";
import XDCaseAddStepPanel from "./XDCaseAddStepPanel";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";
import StepEditor from "./StepEditor";

export default function XDCaseDetail(props: any) {
  const [openAddSeqPanel, setOpenAddSeqPanel] = useState(false);
  const [showAllSeqOptions, setShowAllSeqOptions] = useState(true);
  const [selectedSeqIndex, setSelectedSeqIndex] = useState<number | null>(null);
  const [nextSeqList, setNextSeqList] = useState<any[]>([]);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedProblemId, setSelectedProblemId] = useState<number|null>(null);
  const [problemMode, setProblemMode] = useState<string|null>(null);
  const [seqToDelete, setSeqToDelete] = useState<number | null>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [xdId, setXdId] = useState<string|null>(null);
  const [sequenceCount, setSequenceCount] = useState<number|null>(null);
  const [showAllSteps, setShowAllSteps] = useState(true);
  const [itemToDelete, setItemToDelete] = useState<string|null>(null);
  const [seqToMove, setSeqToMove] =  useState<number|null>(null);
  const [seqToMoveTo, setSeqToMoveTo] = useState<number|null>(null);
  const [showNodeDetail, setShowNodeDetail] = useState(false);
  const [loc, setLoc] = useState<any>(null);
  
  useEffect(() => {
    if (!props.selectedXdCase?.id) return;
    if (props.selectedXdCase.id !== xdId 
        || props.selectedXdCase?.sequence?.length !== sequenceCount) {
      setSelectedSeqIndex(null);
      setSelectedNode(null);
      setSelectedProblemId(null);
    }
    setXdId(props.selectedXdCase.id);
    setSequenceCount(props.selectedXdCase?.sequence?.length || null);
  }, [props.selectedXdCase]);

  useEffect(() => {
    if (Number.isInteger(selectedSeqIndex) && selectedSeqIndex !== null) {
      setShowAllSeqOptions(false);
      let stepId = props.selectedXdCase?.sequence?.[selectedSeqIndex]?.stepId;
      if (stepId) {
        setNextSeqList(
          props.xddata.edges
          .filter((item: any) => item?.from === stepId)
          .map((item: any) => item?.to)
          .filter((item: any) => item)
        );
      }
    } else {
      setShowAllSeqOptions(true)
    }
  }, [selectedSeqIndex]);
  
  return(
    <div style={{width: "100%", height: "89vh"}}>
      <div style={{display: "flex", paddingLeft: "5px"}}>
        <div
          style={{cursor: "pointer", marginRight: "20px"}}
          onClick={() => {
            setOpenAddSeqPanel(!openAddSeqPanel);
          }}>
          {`${openAddSeqPanel ? "<< Back" : "+ Add Sequence"}`}
        </div>
        <div style={{flex: 1}}></div>
        <Checkbox
          label="แสดงรายละเอียดทุก sequence"
          checked={showAllSteps}
          onClick={() => setShowAllSteps(!showAllSteps)}
        />
      </div>
      <div style={{display: "flex", height: "100%"}}>
        {openAddSeqPanel &&
        <div style={{width: "300px", height: "100%"}}>
          <XDCaseAddStepPanel 
            xddata={props.xddata}
            showAllSeqOptions={showAllSeqOptions}
            setShowAllSeqOptions={setShowAllSeqOptions}
            nextSeqList={nextSeqList}
            selectStep={(step: any) => {
              props.onEvent({message: "AddXdCaseSeq", params: {
                xdCaseId: props.selectedXdCase.id,
                stepId: step.id,
                description: step.description,
                seqIndex: selectedSeqIndex
              }})
            }}
          />
        </div>}
        <div style={{flex: 1, overflowY: "auto"}}>
          {(props.selectedXdCase?.sequence || [])
          .map((seq: any, seqIndex: number) => (            
            <div  
              key={seqIndex} 
              style={{
                padding: "2px", border: "solid grey 1px", borderRadius: "4px", margin: "6px",
                backgroundColor: 
                  seqToMove && seqToMove === seqIndex ? "lightgreen" 
                  : selectedSeqIndex === seqIndex ? "#eeeeee" 
                  : "white",
              }}
              draggable={true}
              onDragStart={() => setSeqToMove(seqIndex)}
              onDragEnd={() => {
                if (seqToMove !== seqToMoveTo) {
                  props.onEvent({message: "SwapXdCaseSeq", 
                  params: {xdCaseId: props.selectedXdCase?.id, seqToMove, seqToMoveTo}});
                }
                setSelectedSeqIndex(null);
                setSeqToMove(null);
                setSeqToMoveTo(null);
              }}
              onDragOver={() => {
                setSeqToMoveTo(seqIndex);
              }}
              onClick={async () => {
                if (seqIndex !== selectedSeqIndex) {
                  setSelectedSeqIndex(seqIndex);                  
                } else {
                  setSelectedSeqIndex(null)
                }
              }}>
              {seqToMove && seqIndex === seqToMoveTo && seqToMove > seqToMoveTo &&  
              <div
                style={{ borderTop: "solid red 2px", marginTop: "-7px" }}>
              </div>}
              <div 
                style={{
                  fontWeight: "bold", 
                  fontSize: "1.1rem",
                  display: "flex",
                }}>
                <div>{seq.description}</div>
                <div style={{flex: 1}}></div>
                <Icon 
                  name="delete"
                  onClick={() => {
                    setItemToDelete("sequence");
                    setSeqToDelete(seqIndex);
                    setOpenConfirmDelete(true);
                  }}
                />
              </div>
              {(showAllSteps || (props.xddata?.steps?.[seq.stepId]?.nodeDetail && seqIndex === selectedSeqIndex)) &&
              <motion.div 
                style={{paddingLeft: "10px", opacity: 0}} 
                animate={{opacity: 1}} transition={{duration: 0.5}}>
                {Object.entries(props.xddata.steps[seq.stepId]?.nodeDetail || {})
                .map((entry: [string, any], entryIndex: number) => (
                  <div key={entryIndex} style={{display: "flex", borderBottom: "solid #cccccc 1px"}}>
                    <div style={{flex: 2}}>
                      <div>{props.xddata.nodeMaster[entry[0]]?.name}</div>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{marginRight: "2px"}}>{`[${entry[1].detail}]`}</div>
                        <Icon 
                          name="question" 
                          color="blue"
                          size="tiny"
                          bordered fitted
                          inverted
                          onClick={() => {
                            setLoc({
                              id: entry[0],
                              step: props.xddata.steps[seq.stepId],
                              node: props.xddata.nodeMaster[entry[0]],
                            });
                            setShowNodeDetail(true);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{flex: 5}}>
                      {(seq.problems || [])
                      .filter((problemItem: any) => problemItem.nodeId === entry[0])
                      .map((problemItem: any, problemIndex: number) => {
                        let problemDetail = props.xdProblemDict?.[problemItem.id];
                        return(
                          <div 
                            key={problemIndex}
                            style={{ borderBottom: "solid #cccccc 1px" }}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (problemMode !== "add" && !selectedNode && !selectedProblemId) {
                                setProblemMode("edit");
                                setSelectedNode(entry[0]);
                                setSelectedProblemId(problemItem.id);
                                setSelectedSeqIndex(seqIndex);
                              }
                            }}>
                            {selectedProblemId === problemItem.id && selectedNode === entry[0] ?
                            <XDCaseFormAddorEditProblem
                              onEvent={props.onEvent}
                              xdCaseId={props.selectedXdCase.id}
                              problemMode={problemMode}
                              setProblemMode={setProblemMode}
                              selectedSeqIndex={seqIndex}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                              selectedProblemId={selectedProblemId}
                              setSelectedProblemId={setSelectedProblemId}
                              problemItem={problemItem}
                              issueLatestUpdates={props.issueLatestUpdates}
                              xdProblemDict={props.xdProblemDict}
                              issueTestResult={props.issueTestResult}
                              onRequestDelete={() => {
                                setItemToDelete("problem");
                                setSelectedProblemId(problemDetail.id);
                                setSeqToDelete(seqIndex);
                                setOpenConfirmDelete(true);
                              }}
                            />
                            :
                            <div style={{display: "flex", alignItems: "center"}}>
                              {problemDetail.issues.some((issueId: any) => 
                                props.issueTestResult?.[issueId]?.status === "failed") ?
                              <Icon size="small" name="remove circle" color="red" />
                              
                              : problemDetail.issues.some((issueId: any) => 
                                props.issueTestResult?.[issueId]?.status !== "passed") ?
                              <Icon size="small" name="circle outline"  />
                              
                              :
                              <Icon size="small" name="check circle" color="green" />
                              }
                              <div
                                style={{color: problemDetail?.createdBy === props.user.email ? "red" : "black"}}>
                                {(problemDetail?.issues || []).length > 0 ?
                                `${problemDetail?.description} (${problemDetail.issues.join(", ")})`
                                : `${problemDetail?.description}`}
                              </div>
                              <div style={{flex: 1}}></div>
                              <Icon 
                                name="delete"
                                onClick={() => {
                                  setItemToDelete("problem");
                                  setSelectedProblemId(problemDetail.id);
                                  setSeqToDelete(seqIndex);
                                  setOpenConfirmDelete(true);
                                }}
                              />
                            </div>
                            }
                          </div>
                      )})}
                      {problemMode === "add" && selectedNode === entry[0] && seqIndex === selectedSeqIndex ?
                      <XDCaseFormAddorEditProblem
                        onEvent={props.onEvent}
                        xdCaseId={props.selectedXdCase.id}
                        problemMode={problemMode}
                        setProblemMode={setProblemMode}
                        selectedSeqIndex={seqIndex}
                        selectedNode={selectedNode}
                        setSelectedNode={setSelectedNode}
                        selectedProblemId={null}
                        setSelectedProblemId={setSelectedProblemId}
                        problemItem={null}
                        issueLatestUpdates={props.issueLatestUpdates}
                        xdProblemDict={props.xdProblemDict}
                      />
                      :
                      <div
                        style={{cursor: "pointer"}}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          if (!selectedNode && !selectedProblemId) {
                            setProblemMode("add");
                            setSelectedNode(entry[0]);
                            setSelectedSeqIndex(seqIndex);
                          }
                        }}>
                        + Problem
                      </div>}
                    </div>
                  </div>
                ))}
              </motion.div>}
              {seqToMove && seqIndex === seqToMoveTo && seqToMove < seqToMoveTo &&  
              <div
                style={{ borderTop: "solid red 2px", marginBottom: "-7px" }}>
              </div>}
            </div>
          ))}
        </div>
      </div>
      <Modal
        size="small"
        open={openConfirmDelete}
        closeOnDimmerClick={true}
        closeIcon={true}
        onClose={() => setOpenConfirmDelete(false)}>
        <DeleteConfirmDialog
          itemToDelete={itemToDelete}
          onEvent={props.onEvent}
          selectedXdCase={props.selectedXdCase}
          seqToDelete={seqToDelete}
          selectedProblemId={selectedProblemId}
          setSeqToDelete={setSeqToDelete}
          setSelectedProblemId={setSelectedProblemId}
          setSelectedNode={setSelectedNode}
          setOpenConfirmDelete={setOpenConfirmDelete}
          xdProblemDict={props.xdProblemDict}
        />
      </Modal>
      <Modal
        open={showNodeDetail}
        closeIcon={true}
        closeOnDimmerClick={true}
        onClose={() => {
          setShowNodeDetail(false);
          setLoc(null);
        }}
        >
        {loc ?
        <StepEditor
          xddata={props.xddata}
          debug={props.debug}
          docInfo={props.docInfo}
          editMode={"Role"}
          onEvent={props.onEvent}
          view={props.view}
          loc={loc}
          xdedit={false}
          // Not actually used
          setProp={() => {}}
          trigger={false}
          remove={() => {}}
          doneCellDetail={() => {}}
        />:
        "ไม่พบข้อมูลรายละเอียด"
        }
      </Modal>
    </div>
  )
}

function DeleteConfirmDialog(props: any) {  
  let message;
  if (props.itemToDelete === "sequence") {
    message = props.seqToDelete !== null 
      ? `ยืนยันลบขั้นตอน "${props.selectedXdCase?.sequence?.[props.seqToDelete]?.description}"` 
      : "ไม่พบขั้นตอนนี้ กรุณากด Cancel"
  } else if (props.itemToDelete === "problem")  {
    message = props.selectedProblemId !== null 
      ? `ยืนยันลบปัญหา "${props.xdProblemDict?.[props.selectedProblemId]?.description}"` 
      : "ไม่พบปัญหา กรุณากด Cancel"
  }
  
  return(
    <div style={{padding: "20px"}}>
      <div style={{display: "flex", justifyContent: "center"}}>
        {message}
      </div>
      <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
        <Button
          size="mini" color="red"
          onClick={() => {
            if (props.itemToDelete === "sequence") {
              props.setSeqToDelete(null);
              props.setOpenConfirmDelete(false);
            } else if (props.itemToDelete === "problem") {
              props.setSeqToDelete(null);
              props.setSelectedProblemId(null);
              props.setOpenConfirmDelete(false);
            }
          }}>
          Cancel
        </Button >
        <Button 
          disabled={props.seqToDelete === null || !props.selectedXdCase?.sequence?.[props.seqToDelete]}
          size="mini" color="green"
          onClick={() => {
            if (props.itemToDelete === "sequence") {
              props.onEvent({
                message: "DeleteXdCaseSeq",
                params: {
                  xdCaseId: props.selectedXdCase.id,
                  seqToDelete: props.seqToDelete
                }
              });
              props.setSeqToDelete(null);
              props.setOpenConfirmDelete(false);
            } else if (props.itemToDelete === "problem") {
              props.onEvent({message: "AddOrEditXdProblem", params: {
                mode: "delete",
                xdCaseId: props.selectedXdCase.id,
                seqIndex: props.seqToDelete,
                problemId: props.selectedProblemId,
              }});
              props.setSeqToDelete(null);
              props.setSelectedProblemId(null);
              props.setOpenConfirmDelete(false);
              props.setSelectedNode(null);
            }
          }}>
          Confirm
        </Button>    
      </div>
    </div>
  )
}
