import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react"

export default function VideoRequest(props: any) {
  let [from, setFrom] = useState<string|null>(null);
  let [detail, setDetail] = useState<any>(null);
  let [requestData, setRequestData] = useState<any>(null);

  useEffect(() => {
    if (props.from && props.detail 
        && !(props.from === from && JSON.stringify(props.detail) === JSON.stringify(detail))) {
      setFrom(props.from);
      setDetail(props.detail);
      setRequestData(null);
      props.onEvent({ message: "GetVDORequest", 
        params: { from: props.from, detail: props.detail, setRequestData } 
      });
    }
  }, [props.from, props.detail]);

  return (
    <div>
      {requestData && !requestData.exists ?
      <Button
        size="mini" color="green"
        onClick={(e: any) => {
          props.onEvent({ message: "AddVDORequest", 
            params: { 
              from: props.from, detail: props.detail, 
              requestData, setRequestData } 
          });
        }}>
        <Icon name="video" />
        Request
      </Button>
      
      :requestData && requestData.exists ?
      <Button size="mini" color="red">
        <Icon name="video" />
        Cancel
      </Button>
      
      :<></>}
    </div>
  )
}
