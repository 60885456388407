import { useState } from "react";
import ReactTable from 'react-table-6';
import { Button, Checkbox, Modal } from "semantic-ui-react";
import EditStaffForm from "./EditStaffForm";

export default function StaffList(props: any) {
  const [showInactive, setShowInactive] = useState(false);
  const [showEditStaffForm, setShowEditStaffForm] = useState(false);
  const [editedStaff, setEditedStaff] = useState<any>(null);
  
  return(
    <>
      <div style={{display: "flex"}}>
        <Checkbox 
          label="Show Inactive" 
          checked={showInactive}
          onChange={(e: any) => setShowInactive(!showInactive)}
        />
        <div style={{flex: 1}}></div>
        <Button 
          size="mini" color="blue"
          onClick={(e: any) => {
            setEditedStaff(null);
            setShowEditStaffForm(true);
          }}>
          Add Staff
        </Button>
      </div>
      <ReactTable
        data={
          Object.entries(props?.staffProfile || {})
          .map((entry: [string, any]) => ({id: entry[0], ...entry[1]}))
          .sort((a: any, b: any) => a.id < b.id ? -1 : 1)
          .filter((item: any) => showInactive ? true : item.status !== "Inactive")}
        getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            onClick: (e: any) => {
              props.setSelectedStaff(rowInfo.original.id);
              props.setSubMode("By Staff");
            },
            style: {color: rowInfo?.original?.status === "Inactive" ? "#aaaaaa" : "black"}
          }
        }}
        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            style: {
              textAlign: column.Header === "Amount" ? "right" 
                          : column.Header === "Checked" ? "center"
                          : "left"
            }
          }
        }}
        style={{height: "85vh"}}
        showPagination={false}
        manual
        columns={[
          { Header: "Email", accessor: "email" },
          { Header: "Employee ID", accessor: "employeeId" },
          { Header: "Name", accessor: "name" },
          { Header: "Role", accessor: "role" },
          { Header: "Start", accessor: "start" },
          { Header: "End", accessor: "end" },
          { Header: "Status", accessor: "status",
            Cell: (cellInfo, column)  => {
              return(
                <div style={{display: "flex"}}>
                  <select
                    value={cellInfo?.original?.status || "Active"}
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                    onChange={(e: any) => {
                      let id = cellInfo?.original?.id;
                      if (!id) return;
                      props.onEvent({
                        message: "UpdateStaff", 
                        params: {id, status: e.target.value}
                      });
                    }}>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <Button size="mini" color="blue"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setEditedStaff(cellInfo.original);
                      setShowEditStaffForm(true);
                    }}>
                    Edit Profile
                  </Button>
                </div>
              )
            }}
        ]}
      />
      <Modal
        closeOnDimmerClick={true}
        open={showEditStaffForm}
        onClose={(e: any) => setShowEditStaffForm(false)}>
        <div style={{padding: "10px"}}>
          <EditStaffForm
            editedStaff={editedStaff}
            onEvent={props.onEvent}
            onDone={() => setShowEditStaffForm(false)}
          />
        </div>
      </Modal>
    </>
  )
}