import { StaffProfileData, MonthDetailData } from "./TypeDef";

export const yearSummaryByStaff = (
  year: string, yearYm: any[], 
  staffProfile: StaffProfileData, 
  monthDetail: MonthDetailData
) => {
  const start = `${year}01`;
  const end = `${year}12`;
  let initialData = Object.fromEntries(
    [
      [ "Sum", 
        { 
          id: "Sum", 
          name: "",
          start: "",
          detailDict: Object.fromEntries(yearYm.map((ym: string) => 
                        [ym, {components: {base: 0, stock: 0, dividend: 0, bonus: 0}}]))
        }
      ]
    ].concat(
      Object.entries(staffProfile)
      .filter((entry: [string, any]) => entry[1].status === "Active")
      .map((entry: [string, any]) => (
        [ entry[0],
          {
            id: entry[0],
            name: entry[1].name,
            start: entry[1].start,
            detailDict: {}
          }
        ]
      ))
    )
  );
  
  let yearData_ = Object.entries(monthDetail)
    .filter((entry: [string, any]) => entry[0] >= start && entry[0] <= end)
    .reduce((acc: any, cur: [string, any]) => {
      let [ym, ymData]  = cur;
      for (const item of Object.entries(ymData)) {
        let [staffId, itemDetail]: [string, any] = item;
        let sumComponents = Object.values(itemDetail?.components || {})
          .reduce((acc: number, cur: any) => acc + Number(cur), 0);
        if (sumComponents === 0) continue;
        if (!(staffId in acc)) {
          acc[staffId] = {
            id: staffId,
            name: staffProfile?.[staffId]?.name || "",
            start: staffProfile?.[staffId]?.start || "",
            detailDict: {}
          }
        }
        acc[staffId].detailDict[ym] = itemDetail;
        if (!(ym in acc.Sum.detailDict)) {
          acc.Sum.detailDict[ym] = {
            components: {base: 0, stock: 0, dividend: 0, bonus: 0}
          };
        }
        for (const category of ["base", "stock", "dividend", "bonus"]) {
          acc.Sum.detailDict[ym].components[category] += (itemDetail?.components?.[category] || 0);
        }
      }
      return acc;
    }, initialData);
  return Object.values(yearData_);
}

export const yearSummaryByRole = (
  year: string, yearYm: any[], 
  staffProfile: StaffProfileData, 
  monthDetail: MonthDetailData
) => {
  const start = `${year}01`;
  const end = `${year}12`;
  let initialData = Object.fromEntries(
    [
      [ "Sum", 
        { 
          id: "Sum", 
          name: "",
          start: "",
          detailDict: Object.fromEntries(yearYm.map((ym: string) => 
                        [ym, {components: {base: 0, stock: 0, dividend: 0, bonus: 0}}]))
        }
      ]
    ]
  );
  
  let yearData_ = Object.entries(monthDetail)
    .filter((entry: [string, any]) => entry[0] >= start && entry[0] <= end)
    .reduce((acc: any, cur: [string, any]) => {
      let [ym, ymData]  = cur;
      for (const item of Object.entries(ymData)) {
        let [staffId, itemDetail]: [string, any] = item;
        let role = itemDetail?.role || "[No Role]";
        if (!(role in acc)) {
          acc[role] = {
            id: role,
            name: role,
            start: "",
            detailDict: {}
          }
        }
        if (!(ym in acc[role].detailDict)) {
          acc[role].detailDict[ym] = {
            components: {base: 0, stock: 0, dividend: 0, bonus: 0}
          };
        }
        for (const category of ["base", "stock", "dividend", "bonus"]) {
          acc[role].detailDict[ym].components[category] += (itemDetail?.components?.[category] || 0);
        }
        if (!(ym in acc.Sum.detailDict)) {
          acc.Sum.detailDict[ym] = {
            components: {base: 0, stock: 0, dividend: 0, bonus: 0}
          };
        }
        for (const category of ["base", "stock", "dividend", "bonus"]) {
          acc.Sum.detailDict[ym].components[category] += (itemDetail?.components?.[category] || 0);
        }
      }
      return acc;
    }, initialData);
  return Object.values(yearData_);
}