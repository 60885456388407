import { useEffect, useRef, useState } from "react";
import VegaEmbed from 'ismor-lib/utils/VegaEmbed';
import { query } from "ismor-lib/utils/DuckLib";

export default function Analysis(props: any) {
  const [menu, setMenu] = useState("Base Monthly");
  const [staffList, setStaffList] = useState<any[]>([]);
  const [plotData, setPlotData] = useState<any>(null);

  let displayArea = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.pythonLoaded && props.staffProfile) {  
      staffSummary(props.staffProfile);
    }
  }, [props.pythonLoaded, props.staffProfile])

  useEffect(() => {
    let processData = async () => {
      if (!props.duckLoaded) return console.log("Duck not loaded");
      if (menu === "Base Monthly") {
        let data = await query(`
          from staff_month_detail
          select 
            substring(ym, 0, 5) y, 
            sum(base) base_sum,
            count(distinct ym) month_count
          group by y
          `, 
          props.duckCon);
        data = data
          .map((item: any) => ({ ...item, month_count: Number(item.month_count)}))
          .flatMap((item: any) => ([
          { ...item, type: "normal" },
          {
            ...item, 
            base_sum: item.month_count === 12 ? 0.0 
                      : item.base_sum * 12 / item.month_count - item.base_sum,
            type: "interpolate"
          }
        ]));
        setPlotData(data);
      }    
    }
    processData();
  }, [menu, props.duckLoaded]);

  // Functions ---------------------------------------------------------------------------------
  const staffSummary = async (staffProfile: any) => {
    const result = await props.python.current.run("staffSummary", { 
    staffData: JSON.stringify(Object.values(staffProfile))
    }, `
    import pandas as pd
    import json


    from js import pyParams
    staffData = pyParams.staffData
    staffData = json.loads(staffData)
    
    df = pd.DataFrame(staffData)
    
    json.dumps(df.email.to_list())
    `
    ).catch((err: any) => console.log(err));
    // console.log(result);
    setStaffList(result);
  }
 
  return(
    <div style={{display: "flex", height: "100%"}}>
      <div style={{width: "200px", overflowY: "auto"}}>
        {["Base Monthly", "Staff List" ]
        .map((item: string, index: number) => (
          <div 
            key={index}
            style={{backgroundColor: item === menu ? "lightgreen" : "white"}}
            onClick={(e: any) => setMenu(item)}>
            {item}
          </div>
        ))}
      </div>
      <div 
        ref={displayArea}
        style={{
          flex: 1, paddingLeft: "5px",
          overflowY: "auto", 
          borderLeft: "solid grey 1px"
        }}>
        {menu === "Staff List" ?
        staffList?.map((id: string, index: number) => (
          <div key={index}>{id}</div>
        ))
        
        : menu === "Base Monthly" && plotData ?
        <div>
          <VegaEmbed
            data={{
              data: {values: plotData},
              title: "Month Salary",
              width: (displayArea.current?.clientWidth || 600) * .8 ,
              height: (displayArea.current?.clientHeight || 500) * .8,
              mark: "bar",
              selection: {pts: { type: "point"}},
              encoding: {
                x: { field: "y", type: "ordinal" },
                y: { field: "base_sum", type: "quantitative"},
                color: {
                  field: "type",
                  type: "ordinal",
                },
              },
            }}
          />
        </div>

        : <></>
        } 
      </div>
    </div>
  )
}

