import * as RedmineI from './ismor-lib/apps/REDMINE/RedmineInterface'
import { CreateUIState, CreateUIStateInitial, CreateUIEvent } from "./ismor-lib/apps/UIGEN/CreateUI";
import { CreateUIv2State, CreateUIv2StateInitial, CreateUIv2Event } from "./ismor-lib/apps/UIGEN/CreateUIv2";
import { Pic2UIState, Pic2UIStateInitial, Pic2UIEvent } from "./ismor-lib/apps/UIGEN/Pic2UI"
import { UploadState, UploadStateInitial, UploadEvent } from './ismor-lib/apps/LAB/Upload'
import { MyBPlusState, MyBPlusEvent, MyBPlusStateInitial } from './ismor-lib/apps/LAB/MyBPlus'
import * as PDFI from './ismor-lib/apps/PDF/PDFI'
import * as Stat from './ismor-lib/apps/STAT/StatInterface'
import * as PerformanceI from './ismor-lib/apps/MONITOR/PerformanceI';
import * as PerfI from "./ismor-lib/apps/PERF/PerfI";
import * as ScoringI from './ismor-lib/apps/SCORE/ScoreInterface';
import * as XDI from "./ismor-lib/apps/XD2/XDInterface";
import * as DPI from "./ismor-lib/apps/DP/DPInterface";
import * as ClientI from "./ismor-lib/apps/CLIENT/ClientInterface";
import * as WebI from "./ismor-lib/apps/WEB/WebInterface";
import * as VideoI from "./ismor-lib/apps/IHGAME/VideoInterface";
import * as StaffI from "./ismor-lib/appsadmin/STAFF/StaffInterface";
import * as StoryI from "./ismor-lib/apps/STORY/StoryInterface";
import * as IHGI from "./ismor-lib/apps/IHGAME/IHGI";
import * as LNI from "./ismor-lib/apps/LN/LNInterface"
import * as MEDI from "./ismor-lib/apps/MED/MEDInterface";
import * as HLI from "./ismor-lib/apps/HL/HLInterface";
import * as HZI from "./ismor-lib/apps/HZ/HZInterface";
import * as VDOI from "./ismor-lib/apps/VDO/VDOInterface";

export type ISMORState = 
  {
    authChecked?: boolean,
    loggedin?: boolean,
    adminLoggedin?: boolean,
    webLoggedin?: boolean,
    tab?: string,
    releases?: any[],
    mobileScreen?: boolean,
    screenWidth?: number,
    mobileMenu?: boolean,
    permissions?: any,
    preferences?: any,
    urlParams?: URLSearchParams,
    copyToken?: boolean,
    webviewReady?: boolean,
    pywebviewData?: number,
    notifications?: any,
    pythonLoaded?: boolean,
    pythonError?: any,
    bokehLoaded?: boolean,
    duckLoaded?: boolean,
  }    
  & RedmineI.State 
  & CreateUIState 
  & CreateUIv2State 
  & Pic2UIState 
  & UploadState 
  & MyBPlusState 
  & PDFI.State 
  & XDI.State
  & Stat.State 
  & PerformanceI.State 
  & PerfI.State
  & ScoringI.State
  & DPI.State
  & WebI.State
  & ClientI.State
  & VideoI.State
  & StaffI.State
  & StoryI.State
  & IHGI.State
  & LNI.State
  & MEDI.State
  & HLI.State
  & HZI.State
  & VDOI.State

export const ISMORStateInitial: ISMORState = {
  authChecked: false,
  loggedin: false,
  adminLoggedin: false,
  webLoggedin: false,
  tab: "",
  releases: [],
  mobileScreen: globalThis.innerWidth < 768,
  screenWidth: globalThis.innerWidth,
  mobileMenu: false,
  permissions: {},
  copyToken: false,
  webviewReady: false,
  pywebviewData: 0,
  notifications: {},
  pythonLoaded: false,
  pythonError: null,
  bokehLoaded: false,
  ...RedmineI.StateInitial,
  ...CreateUIStateInitial,
  ...CreateUIv2StateInitial,
  ...Pic2UIStateInitial,
  ...UploadStateInitial,
  ...MyBPlusStateInitial,
  ...PDFI.StateInitial,
  ...XDI.StateInitial,
  ...Stat.StateInitial,
  ...PerformanceI.StateInitial,
  ...PerfI.StateInitial,
  ...ScoringI.StateInitial,
  ...DPI.StateInitial,
  ...WebI.StateInitial,
  ...ClientI.StateInitial,
  ...VideoI.StateInitial,
  ...StaffI.StateInitial,
  ...StoryI.StateInitial,
  ...IHGI.StateInitial,
  ...LNI.StateInitial,
  ...MEDI.StateInitial,
  ...HLI.StateInitial,
  ...HZI.StateInitial,
  ...VDOI.StateInitial
}

export type ISMOREvent = 
  { message: "ISMORDidMount", params: any } 
  | { message: "LoadWasm", params: any } 
  | { message: "LoadSAPMAP", params: any } 
  | { message: "Logout", params: any } 
  | { message: "SavePreferences", params: any } 
  | { message: "DownloadApp", params: any } 
  | { message: "ClearNotifications", params: any } 
  | { message: "SetPin", params: {pin: string} } 
  | RedmineI.Event 
  | CreateUIEvent 
  | CreateUIv2Event 
  | Pic2UIEvent 
  | UploadEvent 
  | MyBPlusEvent 
  | PDFI.Event 
  | XDI.Event
  | Stat.Event 
  | PerformanceI.Event
  | PerfI.Event
  | ScoringI.Event
  | DPI.Event
  | WebI.Event
  | ClientI.Event
  | VideoI.Event
  | StaffI.Event
  | StoryI.Event
  | IHGI.Event
  | LNI.Event
  | MEDI.Event
  | HLI.Event
  | HZI.Event
  | VDOI.Event

export type ISMORData = 
  {
    python?: any,
    duck?: any,
  }
  & XDI.Data
  & RedmineI.Data
  & ClientI.Data
  & VideoI.Data
  & StaffI.Data
  & StoryI.Data
  & IHGI.Data
  & LNI.Data
  & MEDI.Data
  & HLI.Data
  & HZI.Data
  & VDOI.Data

export const ISMORDataInitial = {
  ...RedmineI.DataInitial,  
  ...XDI.DataInitial,
  ...ClientI.DataInitial,
  ...VideoI.DataInitial,
  ...StaffI.DataInitial,
  ...StoryI.DataInitial,
  ...IHGI.DataInitial,
  ...LNI.DataInitial,
  ...MEDI.DataInitial,
  ...HLI.DataInitial,
  ...HZI.DataInitial,
  ...VDOI.DataInitial
}
