import React, { useState, useEffect } from "react";
import { StepView } from "./TypeDef";
import { RoleDetail } from "./StepPanel";
import { Checkbox, Icon } from "semantic-ui-react";
import DocumentManager from "./DocumentManager";

const XDRole = (props: any) => {
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [showNodes, setShowNodes] = useState(true);

  useEffect(() => {
    return () => {
      props.setLoc(null);
    }
  }, []);

  useEffect(() => {
    // setSelectedNodeIds([]);
    // setShowNodes(false);
  }, [props.xddata]);

  const filteredSteps = props.view.stepViews
    .filter((stepView: StepView) => (
      selectedNodeIds.filter((nodeId: string) => (
        Object.keys(stepView.step.nodeDetail).includes(nodeId)
      )).length > 0
    ));

  const nodeMaster = props.xddata.nodeMaster;

  return(
    <div style={{padding: "5px", height: "92vh", overflowY: "hidden"}}>
      <div 
        style={{
          display: "flex", height: "3vh", justifyContent: "flex-start" , 
          borderBottom: "solid black 1px"
        }}>
        <div style={{display: "flex"}}>
          <Icon 
            name="list"
            onClick={(e: any) => {
              setShowNodes(!showNodes || selectedNodeIds.length == 0)
            }}
          />
          <div
            style={{
              padding: "0 5px 0 5px", 
              margin: "0 1px 0 1px",
              cursor: "pointer",
              backgroundColor: "#eeeeee",
            }}>
            {`Selected Roles: ` + (selectedNodeIds.length === 0 ? "" :
              selectedNodeIds.map((nodeId: string) => 
              nodeMaster[nodeId] ? ` ${nodeMaster[nodeId].name} (${nodeMaster[nodeId].system})` : ""
              ))}
          </div>
        </div>
      </div>
      <div style={{display: "flex", height: "87vh", overflowY: "hidden"}}>
        {showNodes &&
        <div 
          style={{
            // position: "absolute", 
            backgroundColor: "white", 
            // maxHeight: "600px", 
            overflowY: "scroll"
          }}>
          {props.xddata.nodes.map(
            (nodeId: string, nodeIndex: number) => (
              <div 
                key={nodeIndex}
                style={{
                  // backgroundColor: "orange", 
                  color: "black", 
                  margin: "0px 2px 2px 2px",
                  cursor: "pointer"
                }}
                onClick={(e: any) => {
                  if (!(selectedNodeIds.includes(nodeId))) {
                    setSelectedNodeIds([...selectedNodeIds, nodeId]);
                    props.setLoc(null);
                  } else {
                    setSelectedNodeIds(
                      selectedNodeIds.filter((item: string) => item !== nodeId)
                    );
                    props.setLoc(null);
                  }
                }}>
                <Checkbox 
                  label={`${nodeMaster[nodeId].name} (${nodeMaster[nodeId].system})`}
                  checked={selectedNodeIds.includes(nodeId)}
                />
              </div>
            )
          )}
        </div>}
        <div style={{width: "400px", overflowY: "scroll"}}>
        {filteredSteps.map((step: StepView, index: number) => (
          <div 
            key={index} 
            style={{paddingTop: "5px"}}>
            <b
              onClick={(e: any) => {
                if (props.debug) {
                  props.setLoc({step: step.step});
                }
              }}  >
              {`${step.sectionIndex + 1}.${step.stepIndex + 1}. ${step.name} ` + 
              `(${step.sectionIndex + 1} ${step.sectionName}) -> ${step.nextSteps}`}
            </b>
            <div style={{marginLeft: "20px"}}>
              {Object.entries(step.step.nodeDetail)
                .filter(([nodeId, detail]: [string, any], nodeIndex: number) =>
                  detail.type === "editor")
                .map(([nodeId, detail]: [string, any], nodeIndex: number) =>
                  <RoleDetail
                    key={nodeIndex}
                    nodeId={nodeId}
                    detail={detail}
                    nodeMaster={nodeMaster}
                    selectedNodeIds={selectedNodeIds}
                    step={step}
                    loc={props.loc}
                    setLoc={props.setLoc}
                    selectedRole={props.loc?.id}
                    editMode={"Filter"}
                  />)}
              {Object.entries(step.step.nodeDetail)
              .filter(([nodeId, detail]: [string, any], nodeIndex: number) =>
                detail.type === "reviewer")
              .map(([nodeId, detail]: [string, any], nodeIndex: number) =>
                <RoleDetail
                  key={nodeIndex}
                  nodeId={nodeId}
                  detail={detail}
                  nodeMaster={nodeMaster}
                  selectedNodeIds={selectedNodeIds}
                  step={step}
                  loc={props.loc}
                  setLoc={props.setLoc}
                  selectedRole={props.loc?.id}
                  editMode={"Filter"}
                />)}
            </div>
          </div>
        ))}
        </div>
        <div style={{flex: 1, overflowY: "auto", position: "relative"}}>
          {props.loc && props.loc?.step && props.loc?.id ?
          <DocumentManager 
            xdedit={props.xdedit}
            debug={props.debug}
            onEvent={props.onEvent}
            stepId={props.loc.step.id}
            loc={props.loc}
            trigger={props.trigger}
            docInfo={props.docInfo}
          />
          
          : selectedNodeIds.length > 0 ?
          <div>กรุณาเลือก Node จากด้านซ้าย</div>
          
          :<></>}
        </div>
      </div>
    </div>
  )
}

export default XDRole;
