import { useEffect, useRef, useState } from "react";
import XLSX from 'xlsx';
import { monthListOfYear, previousYm, thisMonth } from "./Utils";
import { Icon, Modal } from "semantic-ui-react";
import LetterForm from "./LetterForm";
import { yearSummaryByStaff, yearSummaryByRole } from "./YearSummary";

type EditedDetail = {
  staffId: string,
  ym: string,
  value: string,
}

export default function ByYear(props: any) {
  const [editedDetail, setEditedDetail] = useState<EditedDetail | null>(null);
  const [yearSum, setYearSum] = useState<any[]>([]);
  const [year, setYear] = useState<string>(thisMonth.substring(0, 4));
  const [category, setCategory] = useState("base");
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedYm, setSelectedYm] = useState<string | null>(null);
  const [letter, setLetter] = useState<{[key: string]: string}>({pre: "", post: ""});
  const [openLetter, setOpenLetter] = useState(false);
  const [viewMode, setViewMode] = useState("staff");

  const yearYm = monthListOfYear(year);

  useEffect(() => {
    if (Object.keys(props.staffDict).length === 0) return;
    let yearSum_: any[] = [];
    if (viewMode === "staff") {
      yearSum_ = yearSummaryByStaff(year, yearYm, props.staffProfile, props.monthDetail);
    } else {
      yearSum_ = yearSummaryByRole(year, yearYm, props.staffProfile, props.monthDetail);
    } 
    setYearSum(yearSum_);
    // console.log(yearSum_)
  }, [year, props.staffDict, props.triggerStaff, viewMode]);

  useEffect(() => {
    if (editedDetail !== null)
      inputRef?.current?.focus();
  }, [editedDetail]);

  useEffect(() => {
    if (selectedYm !== null) {
      setLetter(props.letterDict[selectedYm]);
    } else {
      setLetter({pre: "", post: ""});
    }
  }, [selectedYm]);

  return(
    <div style={{width: "100%"}}>
      <div
        style={{display: "flex", justifyContent: "space-between", cursor: "pointer"}}>
        <div>
          {props.staffplus &&
          <div style={{display: "flex"}}>
            <select
              value={category}
              onChange={(e: any) => setCategory(e.target.value)}>
              <option value="base">Base</option>
              <option value="stock">Stock</option>
              <option value="dividend">Dividend</option>
              <option value="bonus">Bonus</option>
            </select>
          </div>}
        </div>
        <div>
          <span 
            onClick={(e: any) => setYear((Number(year) - 1).toString())}>
            &lt;&lt;&nbsp;
          </span>
          <span>{year}</span>
          <span
            onClick={(e: any) => setYear((Number(year) + 1).toString())}>
            &nbsp;&gt;&gt;
          </span>
        </div>
        <div style={{display: "flex"}}>
          <div 
            style={{marginRight: "10px", backgroundColor: viewMode === "staff" ? "#eeeeee" : "white"}}
            onClick={(e: any) => {
              setViewMode("staff");
            }}>
            Staff
          </div>
          <div
            style={{backgroundColor: viewMode === "role" ? "#eeeeee" : "white"}}
            onClick={(e: any) => {
              setViewMode("role");
            }}>
            Role
          </div>
        </div>
      </div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "15% " + `${85 / 12}% `.repeat(12)
        }}>
        {/* Top row with ym ---------------------------------------------------------------------------------------- */}
        {[""].concat(yearYm)
        .map((ym: string, ymIndex: number) => (
          <div 
            key={ymIndex}
            style={{
              border: "solid #cccccc .5px", padding: "2px",
              fontWeight: "bold", 
              backgroundColor: props.letterDict?.[ym] ? "orange": "#eeeeee",
            }}>
            <div style={{textAlign: "right"}}>
              <span style={{cursor: "pointer"}}>{ym}</span>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {ym !== "" && 
              <>
                {!(ym in props.monthDetail) && (previousYm(ym) in props.monthDetail) &&
                <Icon name="arrow right" color="orange" 
                  onClick={(e: any) => props.onEvent({message: "AddMonthDetail", params: {ym}})}
                />}
                <div style={{flex: 1}}></div>
                <Icon 
                  name="folder" color="blue"
                />
                {category === "dividend" && 
                <>
                  <Icon 
                    name="download" color="blue"
                    onClick={(e: any) => {
                      let workbook = XLSX.utils.book_new();
                      workbook.SheetNames.push("Data");
                      let sheet_data = yearSum
                        .filter((staffItem: any) => (
                          staffItem.detailDict[ym]?.components?.dividend > 0
                        ))
                        .sort((a: any, b: any) => (
                          props.staffProfile[a.id]?.employeeId 
                            < props.staffProfile[b.id]?.employeeId ? -1 : 1
                        ))
                        .map((staffItem: any) => ([
                          staffItem.id === "Sum" ? "Employee ID"
                            : props.staffProfile[staffItem.id]?.employeeId,
                          staffItem.id, 
                          staffItem.detailDict[ym]?.components?.dividend
                        ]));
                      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);
                      workbook.Sheets["Data"] = sheet;
                      XLSX.writeFile(workbook, `${ym}.xlsx`, { bookType: "xlsx", type: "buffer" });
                    }}
                  />
                  <Icon 
                    name="envelope" color="blue"
                    onClick={(e: any) => {
                      setSelectedYm(ym);
                      setOpenLetter(true);
                    }}
                  />
                </>}
              </>
              }
            </div>
          </div>
        ))}
        {/* Data table ----------------------------------------------------------------------------------------*/}
        {yearSum
        .sort((a: any, b: any) => a.id < b.id ? -1 : 1)
        .flatMap((staffItem: any, staffIndex: number) => (
          ["name"].concat(yearYm)
          .map((column: string, columnIndex: number) => (
            column === "name" ?
            <div 
              style={{
                border: "solid #cccccc .5px", textAlign: "left",
                fontWeight: staffItem.id === "Sum" ? "bold": "normal",
                color: props.staffProfile?.[staffItem.id]?.status === "Inactive" 
                      ? "grey" : "black"
              }}
              key={`${staffIndex}:${columnIndex}`}>
              {staffItem.id}
            </div>
            :
            <div
              style={{
                border: "solid #cccccc .5px", textAlign: "right",
                fontWeight: staffItem.id === "Sum" ? "bold": "normal",
                color: props.staffProfile?.[staffItem.id]?.status === "Inactive" 
                      ? "grey" : "black"
              }}
              onClick={(e: any) => {
                if (viewMode === "role") return console.log("Role view is read only");
                // Need to handle dividend per share in a different way
                // if (category === "dividend") {
                //   if (staffItem.id !== "Sum") return;
                //   setEditedDetail({
                //     staffId: staffItem.id,
                //     ym: column,
                //     value: props.dividendDict[column] || 0
                //   })
                // } else {
                //   if (staffItem.id === "Sum") return;
                //   setEditedDetail({
                //     staffId: staffItem.id,
                //     ym: column,
                //     value: staffItem.detailDict?.[column]?.components?.[category]
                //   })
                // }
                if (staffItem.id === "Sum") return;
                setEditedDetail({
                  staffId: staffItem.id,
                  ym: column,
                  value: staffItem.detailDict?.[column]?.components?.[category]
                })
              }}
              key={`${staffIndex}:${columnIndex}`}>
              {editedDetail?.staffId === staffItem.id && editedDetail?.ym === column ?
              <input 
                ref={inputRef}
                value={editedDetail?.value}
                onBlur={(e: any) => {
                  // Need to handle dividend per share in a different way
                  // if (category === "dividend") {
                  //   props.onEvent({message: "SetDividend",
                  //     params: {ym: editedDetail.ym, value: editedDetail.value}});
                  // } else {
                  //   props.onEvent({message: "UpdateStaffDetail",
                  //     params: {
                  //       staffId: editedDetail.staffId, ym: editedDetail.ym,
                  //       detailItem: {
                  //         ...staffItem.detailDict?.[column],
                  //         components: {
                  //           ...staffItem.detailDict?.[column]?.components,
                  //           ...({[category]: Number(editedDetail.value)})
                  //         }
                  //       }
                  //     }
                  //   });
                  // }
                  props.onEvent({message: "UpdateStaffDetail",
                    params: {
                      staffId: editedDetail.staffId, ym: editedDetail.ym,
                      detailItem: {
                        ...staffItem.detailDict?.[column],
                        components: {
                          ...staffItem.detailDict?.[column]?.components,
                          ...({[category]: Number(editedDetail.value)})
                        }
                      }
                    }
                  });
                  setEditedDetail(null);
                }}
                onKeyUp={(e: any) => {
                  if (e.key !== "Enter") return;
                  // Need to handle dividend per share in a different way
                  // if (category === "dividend") {
                  //   props.onEvent({message: "SetDividend",
                  //     params: {ym: editedDetail.ym, value: editedDetail.value}});
                  // } else {
                  //   props.onEvent({message: "UpdateStaffDetail",
                  //     params: {
                  //       staffId: editedDetail.staffId, ym: editedDetail.ym,
                  //       detailItem: {
                  //         ...staffItem.detailDict?.[column],
                  //         components: {
                  //           ...staffItem.detailDict?.[column]?.components,
                  //           ...({[category]: Number(editedDetail.value)})
                  //         }
                  //       }
                  //     }
                  //   });
                  // }
                  props.onEvent({message: "UpdateStaffDetail",
                    params: {
                      staffId: editedDetail.staffId, ym: editedDetail.ym,
                      detailItem: {
                        ...staffItem.detailDict?.[column],
                        components: {
                          ...staffItem.detailDict?.[column]?.components,
                          ...({[category]: Number(editedDetail.value)})
                        }
                      }
                    }
                  });
                  setEditedDetail(null);
                }}
                onChange={(e: any) => {
                  if (Number.isNaN(Number(e.target.value + "0"))) return;
                  setEditedDetail({
                    ...editedDetail,
                    value: e.target.value === "0" ? "0" 
                            : e.target.value.replace(/^0+/, '')
                  })
                }}
                style={{width: "100%"}}
              />
              :
              staffItem.detailDict?.[column]?.components?.[category]
                ?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
          ))
        ))}
      </div>      
      <Modal
        open={openLetter}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setSelectedYm(null);
          setOpenLetter(false);
        }}>
        <LetterForm 
          onEvent={props.onEvent}
          setSelectedYm={setSelectedYm}
          selectedYm={selectedYm}
          setLetter={setLetter}
          letter={letter}
          yearSum={yearSum}
          letterDict={props.letterDict}
          setOpenLetter={setOpenLetter}
        />
      </Modal>
    </div>
  )
}
