import React, { useState, useEffect, useRef } from "react";
import { StepView, EdgeView, SectionView } from "./TypeDef";
import { Icon, Modal, Input } from "semantic-ui-react";
import { getRoleType, sectionTitle } from "./XdDetail";
import StepPanel from "./StepPanel";
import StepEditor from "./StepEditor";
import StepRoleEditor from "./StepRoleEditor";

const XDGraph = (props: any) => {

  const [fromStep, setFromStep] = useState<string | null>(null);
  const [toStep, setToStep] = useState<string | null>(null);
  const [fromSectionStep, setFromSectionStep] = useState<any>(null);
  const [toSectionStep, setToSectionStep] = useState<any>(null);
  const [dragMode, setDragMode] = useState<string>("Edge");
  const [showRoleEditor, setShowRoleEditor] = useState(false);
  const [initialScroll, setInitialScroll] = useState(false);
  const [hoverEdgeIndex, setHoverEdgeIndex] = useState<number | null>(null);
  const [hoverStepId, setHoverStepId] = useState<number | null>(null);
  const [movingLine, setMovingLine] = useState(false);
  const [toEdgeIndexTrigger, setToEdgeIndexTrigger] = useState<number | null>(null);
  const [investigate, setInvestigate] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<"Description" | "Role">("Description");
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [urlRoute, setUrlRoute] = useState(true);
  
  const fromEdgeIndex = useRef<number | null>(null);
  const toEdgeIndex = useRef<number | null>(null);

  useEffect(() => { 
    setInitialScroll(true);
    props.setLoc(null);
  }, []);

  useEffect(() => {
    if (!urlRoute) return;
    const stepId = props.urlParams.get('stepId');
    if (!stepId || Object.keys(props.view?.stepDict).length === 0) return;
    if (!props.loc?.step) {
      props.setLoc({ step: props.view.stepDict[stepId]});
      if (props.urlParams.get("investigate"))
        setInvestigate(props.urlParams.get("investigate") || false)
      setUrlRoute(false);
    }
  }, [props.view]);
  
  useEffect(() => { 
    if (initialScroll 
        && props.sectionRefs?.[props?.selectedSectionIndex]?.current
    ){
      props.sectionRefs[props.selectedSectionIndex]
        .current
        .scrollIntoView(true);
      setInitialScroll(false);
    }
  }, [initialScroll]);

  const stopMovingLine = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.xdedit) return;
    props.onEvent({
      message: "MoveEdge",
      params: {fromEdgeIndex: fromEdgeIndex.current, toEdgeIndex: toEdgeIndex.current}
    })
    setMovingLine(false);
    setHoverEdgeIndex(null);
    fromEdgeIndex.current = null;
    toEdgeIndex.current = null;
    setToEdgeIndexTrigger(null);
    document.removeEventListener("mouseup", stopMovingLine);
  }

  const startMovingLine = (e: any, edgeIndex: number) => {
    if (!movingLine) {
      e.preventDefault();
      e.stopPropagation();
      if (!props.xdedit) return;
      fromEdgeIndex.current = edgeIndex;
      setMovingLine(true);
      document.addEventListener("mouseup", stopMovingLine);
    }
  }

  const view = props.view;
  const height = props.view.sectionViews
                  .map((view: any) => view.height)
                  .reduce((acc: number, cur: number) => acc + cur, 0);

  return(
    <div style={{display: "flex", height: "90vh"}}>
      {!investigate &&
      <div style={{flex: 3, border: "solid #cccccc 1px", overflowY: "auto"}}>
        {genSVG(
          props,
          view, 
          height,
          setToSectionStep,
          dragMode,
          toSectionStep,
          fromStep,
          toStep,
          setDragMode,
          setToStep,
          setFromStep,
          fromSectionStep,
          setFromSectionStep,
          toEdgeIndexTrigger,
          toEdgeIndex,
          setToEdgeIndexTrigger,
          hoverEdgeIndex,
          setHoverEdgeIndex,
          hoverStepId,
          setHoverStepId,
          movingLine,
          startMovingLine,
        )}
      </div>}
      
      {props.loc?.step?.id &&
      <div style={{width: "400px", border: "solid #cccccc 1px", padding: "5px"}}>
        <StepPanel
          xdId={props.xdId}
          debug={props.debug}
          xdedit={props.xdedit}
          onEvent={props.onEvent}
          setProp={props.setProp}
          xddata={props.xddata}
          view={props.view}
          loc={props.loc}
          setLoc={props.setLoc}
          nodeMaster={props.nodeMaster}
          stepRefs={props.stepRefs}
          investigate={investigate}
          setInvestigate={setInvestigate}
          editMode={editMode}
          setEditMode={setEditMode}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          setShowRoleEditor={setShowRoleEditor}
          dragMode={dragMode}
          fromStep={fromStep}
          toStep={toStep}
          setToStep={setToStep}
        />
      </div>}      
      
      {investigate && props.loc?.step?.id &&
      <div style={{flex: 3, border: "solid #cccccc 1px", height: "100%"}}>
        <StepEditor
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          view={props.view}
          loc={props.loc}
          editMode={editMode}
          setEditMode={setEditMode}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          xddata={props.xddata}
          remove={props.remove}
          doneCellDetail={props.doneCellDetail}
          trigger={props.trigger}
          docInfo={props.docInfo}
        />
      </div>}
      
      <Modal
        style={{minHeight: "80%"}}
        size="large"
        open={props.loc !== null && props.loc?.step?.id && props.loc?.node && !investigate}
        onClose={(e: any) => { props.loc?.step && props.setLoc({ step: props.loc.step})}}
        closeIcon={true}
        closeOnDimmerClick={true}>
        <StepEditor
          here={"yes"}
          xdedit={props.xdedit}
          debug={props.debug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          view={props.view}
          loc={props.loc}
          editMode={editMode}
          setEditMode={setEditMode}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          xddata={props.xddata}
          remove={props.remove}
          doneCellDetail={props.doneCellDetail}
          trigger={props.trigger}
          docInfo={props.docInfo}
        />
      </Modal>

      <Modal
        open={showRoleEditor}
        onClose={(e: any) => { setShowRoleEditor(false)}}
        closeIcon={true}
        closeOnDimmerClick={true}
        >
        <StepRoleEditor
          xddata={props.xddata}
          loc={props.loc}
          setShowRoleEditor={setShowRoleEditor}
          xdedit={props.xdedit}
          onEvent={props.onEvent}
        />
      </Modal>
      
    </div>
  )
}

export const genSVG = (
  props: any,
  view: any, 
  height: number,
  setToSectionStep: any,
  dragMode: string,
  toSectionStep: any,
  fromStep: string | null,
  toStep: string | null,
  setDragMode: any,
  setToStep: any,
  setFromStep: any,
  fromSectionStep: any,
  setFromSectionStep: any,
  toEdgeIndexTrigger: number | null,
  toEdgeIndex: any,
  setToEdgeIndexTrigger: any,
  hoverEdgeIndex: any,
  setHoverEdgeIndex: any,
  hoverStepId: any,
  setHoverStepId: any,
  movingLine: boolean,
  startMovingLine: any,

) => {

  return(
    <svg 
      width="100%"
      style={{backgroundColor: "white"}}
      viewBox={`0 0 ${props.layout.width} ${height}`}>
      {view?.gapViews
      .map((gapView: any, gap_index: number) => (
        <g
          key={gap_index}
          onDragOver={(e: any) => {
            e.preventDefault();
            if (dragMode === "Step") {
              setToSectionStep({
                sectionIndex: gapView.sectionIndex,
                stepIndex: gapView.stepIndex
              });
            }
          }}>
          <foreignObject 
            key={`gap${gap_index}`} x={gapView.x} y={gapView.y} 
            width={gapView.width} height={gapView.height}>
            <div style={{backgroundColor: "white", height: "100%"}} />
          </foreignObject>
          {toSectionStep?.sectionIndex === gapView.sectionIndex 
            && toSectionStep?.stepIndex === gapView.stepIndex &&
            <line 
              x1={gapView.x}
              y1={gapView.y + gapView.height / 2}
              x2={gapView.x + gapView.width}
              y2={gapView.y + gapView.height / 2}
              style={{
                stroke: "red", 
                strokeWidth: 1,
              }}
            />}
        </g>
      ))}
      {view?.sectionViews.map((sectionView: SectionView, sectionIndex: number) => (
        <g 
          key={`section${sectionIndex}`}
          ref={props.sectionRefs[sectionIndex]}
          onClick={(e: any) => {
            props.setSelectedSectionIndex(sectionIndex);
            props.setSectionDetail(true);
          }}>
          <rect 
            x={sectionView.x}
            y={sectionView.y}
            width={sectionView.width}
            height={sectionView.height}
            fill="none"
            stroke="black"
          />
          <foreignObject
            x={sectionView.x}
            y={sectionView.y}
            width={sectionView.width}
            height={sectionTitle}
          >
            <div 
              style={{
                width: "100%", 
                height: "100%",
                backgroundColor: sectionView.inactive ? "#cccccc" : "#e75480", 
                color: "white",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "0.8em",
                display: "flex",
                justifyContent: "space-between"
              }}>
              <div>{`${sectionIndex + 1}. ${sectionView.name}`}</div>
              <div style={{display: "flex"}}>
                {sectionIndex > 0 &&
                <Icon 
                  name="angle up"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!props.xdedit) return;
                    props.onEvent({
                      message: "MoveSection", 
                      params: {fromSection: sectionIndex, toSection: sectionIndex - 1}
                    });
                  }}
                />}
                {sectionIndex + 1 < props.sections.length && 
                <Icon 
                  name="angle down"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!props.xdedit) return;
                    props.onEvent({
                      message: "MoveSection", 
                      params: {fromSection: sectionIndex, toSection: sectionIndex + 1}
                    });
                  }}
                />}
                <Icon 
                  name="delete"
                  color={props.foldedSections.includes(sectionIndex) ? "teal" : undefined}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!props.foldedSections.includes(sectionIndex)) {
                      props.setFoldedSections([...props.foldedSections, sectionIndex]);
                    } else {
                      props.setFoldedSections(props.foldedSections.filter(
                        (index: number) => index !== sectionIndex));
                    }
                  }}
                />
                <Icon 
                  name="plus"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!props.xdedit) return;
                    props.onEvent({message: "AddSection", params: {sectionIndex}})
                  }}
                />
              </div>
            </div>
          </foreignObject>
        </g>
      ))}
      {view?.stepViews
        .filter((stepView: StepView, step_index: number) => (!stepView.hide))
        .map((stepView: StepView, step_index: number) => (
        <foreignObject 
          key={`step${step_index}`} 
          x={props.showSlim && hoverStepId !== stepView.id ? stepView.minX : stepView.x} 
          y={stepView.y} 
          width={props.showSlim && hoverStepId !== stepView.id ? stepView.slimWidth: stepView.width} 
          height={stepView.height}
          ref={props.stepRefs[stepView.id]}>
          <div
            style={{
              backgroundColor: 
                fromStep === stepView?.step?.id ? "green"
                  : toStep === stepView?.step?.id ? "yellow" 
                  : stepView?.isRoot && stepView?.step?.dependOn?.length === 0 ? "lightgrey"
                  : stepView?.isRoot ? "cyan"
                  : stepView?.step?.dependOn?.length === 0 ? "orange" 
                  : "#5dbcd2"
                ,
              width: "100%", 
              height: "100%", 
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "flex-start",
              fontSize: "0.8em",
              border: props.loc?.step?.id && props.loc.step.id === stepView.id 
                      ? "solid magenta 1px" : "none"
            }}
            draggable={props?.xdedit || false}
            onDragStart={(e: any) => {
              if (!props.moveLine) {
                setDragMode("Edge");
                setFromStep(stepView?.step?.id || null);
              }
            }}
            onDragOver={(e: any) => {
              e.preventDefault();
              if (dragMode === "Edge") {
                if (fromStep && fromStep !== stepView?.step?.id)
                setToStep(stepView?.step?.id || null)
              }
            }}
            onDragEnd={(e: any) => {
              if (!props.xdedit) return;
              if (dragMode === "Edge") {
                if (fromStep && toStep)
                  props.onEvent({
                    message: "AddXdDependOn", 
                    params: {
                      from: fromStep,
                      to: toStep
                    }})
                setFromStep(null);
                setToStep(null);
              }
            }}
            onMouseEnter={(e: any) => { setHoverStepId(stepView.id); }}
            onMouseLeave={(e: any) => { setHoverStepId(null); }}
            onClick={(e: any) => {
              if (stepView.id !== props.loc?.step?.id)
                props.setLoc({step: stepView.step})
              else 
                props.setLoc(null);
              // props.setStepDetail(true)
            }}
            >
            {!props.showDetail && <div></div>}
            <div
              style={{textAlign: props.showDetail ? "left" : "center", position: "relative"}}>
              <div
                style={{position: "relative"}}
                draggable={props?.xdedit || false}
                onDragStart={(e: any) => {
                  if (!props.moveLine) {
                    e.stopPropagation();
                    if (!props.xdedit) return;
                    setDragMode("Step");
                    setFromSectionStep({
                      sectionIndex: stepView.sectionIndex, 
                      stepIndex: stepView.stepIndex
                    });
                  }
                }}
                onDragEnd={(e: any) => {
                  if (!props.xdedit) return;
                  if (!(fromSectionStep?.sectionIndex === toSectionStep?.sectionIndex &&
                    fromSectionStep?.stepIndex === toSectionStep?.stepIndex)) {
                    props.onEvent({
                      message: "MoveXdStep",
                      params: {fromStep: fromSectionStep, toStep: toSectionStep}
                    })  
                  }
                  setFromSectionStep(null);
                  setToSectionStep(null);
                }}
                >
                {props.showSlim && hoverStepId !== stepView.id ? 
                  `${stepView.seq}` 
                  : `${stepView.seq}. ${stepView.name} (${stepView.nextSteps})`
                }
              </div>
              {props.showDetail && 
              <div
                style={{display: "flex"}}>
                {(Object.entries(stepView.step?.nodeDetail) || [])
                    .filter((entries: [string, any]) => entries[1].type === "editor")
                    .map((entries: [string, any], index: number) => 
                      <div
                        key={`editor:${index}`}
                        style={{
                          backgroundColor: getRoleType(entries[1].type), fontSize: "0.7em",
                          marginRight: "5px", width: "8vw", height: "40px", overflow: "auto"
                        }}
                      >
                      <b>{`${props.xddata.nodeMaster[entries[0]].name} (${props.xddata.nodeMaster[entries[0]].system})`}</b>
                      <div>
                        {`${entries[1].detail})`}
                      </div>
                    </div>)
                }
                {(Object.entries(stepView.step?.nodeDetail) || [])
                    .filter((entries: [string, any]) => entries[1].type !== "editor")
                    .map((entries: [string, any], index: number) => 
                      <div
                        key={`non_editor:${index}`}
                        style={{
                          backgroundColor: getRoleType(entries[1].type), fontSize: "0.7em",
                          marginRight: "5px", width: "8vw", height: "40px", overflow: "auto"
                        }}
                      >
                      <b>{`${props.xddata.nodeMaster[entries[0]].name} (${props.xddata.nodeMaster[entries[0]].system})`}</b>
                      <div>
                        {`${entries[1].detail}`}
                      </div>
                    </div>)
                }
              </div>}
            </div>
            <div>
              {/* {step_index === 0 ? <></> :
              <Icon
                name="angle up"
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.swapStep([step_index - 1, step_index])
                }}
              />}
              {step_index === view?.stepViews.length - 1 ? <></> :<Icon
                name="angle down"
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.swapStep([step_index, step_index + 1])
                }}
              />} */}
              {/* <Icon 
                name="angle right"
                disabled={props.moveLine}
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.setLoc({step: stepView.step});
                  setInvestigate(true);
                  setEditMode("Description");
                }}
              /> */}
              <Icon 
                name="plus"
                disabled={props.moveLine}
                onClick={(e: any) => {
                  if (!props.moveLine) {
                    e.stopPropagation();
                    if (!props.xdedit) return;
                    props.addStep(stepView.step, stepView.stepIndex, stepView.step.sectionIndex)
                  }
                }}
              />
            </div>
          </div>     
        </foreignObject>
      ))}
      {props.moveLine && view?.edgeViews
        .map((edgeView: EdgeView, edgeIndex: number) => (
          <line 
            key={`guide${edgeIndex}`}
            x1={edgeView.start.x - view.space / 2}
            y1={0}
            x2={edgeView.start.x - view.space / 2}
            y2={height}
            style={{stroke: toEdgeIndexTrigger === edgeIndex ? "magenta" : "#eeeeee", strokeWidth: "1"}}
            onMouseOver={(e: any) => {
              if (movingLine) {
                toEdgeIndex.current = edgeIndex;
                setToEdgeIndexTrigger(edgeIndex);
              }
            }}
          />
        ))
        .concat(
          <line
            key={`edge${view.edgeViews.length}`}
            x1={view.edgeViews[view.edgeViews.length - 1].start.x + view.space / 2}
            y1={0}
            x2={view.edgeViews[view.edgeViews.length - 1].start.x + view.space / 2}
            y2={height}
            style={{stroke: toEdgeIndexTrigger === view.edgeViews.length ? "magenta" : "#eeeeee", strokeWidth: "1"}}
            onMouseOver={(e: any) => {
              if (movingLine) {
                toEdgeIndex.current = view.edgeViews.length;
                setToEdgeIndexTrigger(view.edgeViews.length);
                // console.log(view.edgeViews.length)
              }
            }}
          />
        )
      }
      {view?.edgeViews
        .filter((edgeView: EdgeView) => !edgeView.hide)
        .map((edgeView: EdgeView, edgeIndex: number) => (
        <g 
          key={`edge${edgeIndex}`}
          onMouseDown={(e: any) => {
            if (props.moveLine) {
              startMovingLine(e, edgeIndex);
            }
          }}>
          <line
            x1={edgeView.start.x}
            y1={edgeView.start.y}
            x2={edgeView.end.x}
            y2={edgeView.end.y}
            style={{
              stroke:edgeIndex === hoverEdgeIndex ? "green" 
                : edgeView.start.y > edgeView.end.y ? "blue" 
                : "red", 
              strokeWidth: edgeIndex === hoverEdgeIndex ? 3 : 1,
              ...(edgeView.optional ? {strokeDasharray: "5, 5"} : {})
            }}
            onClick={(e: any) => {
              if (!props.xdedit) return;
              if (!props.moveLine){
                props.onEvent({
                  message: "ToggleXdDependOnOptional",
                  params: {
                    from: edgeView.end.id,
                    to: edgeView.start.id
                  }
                });
                setHoverEdgeIndex(null);
              }
              
            }}
            onMouseOver={(e: any) => {
              if (!movingLine)
                setHoverEdgeIndex(edgeIndex);
            }}
            onMouseOut={(e: any) => {
              if (!movingLine)
                setHoverEdgeIndex(null);
            }}
          />
          <circle
            cx={edgeView.end.x}
            cy={edgeView.end.y}
            r={`${edgeIndex === hoverEdgeIndex ? "3" : "2"}`}
            fill={edgeIndex === hoverEdgeIndex ? "green" 
              : edgeView.start.y > edgeView.end.y ? "blue" : "red"}
            onClick={(e: any) => {
              if (!props.xdedit) return;
              if (!props.moveLine) {
                props.onEvent({
                  message: "RemoveXdDependOn",
                  params: {
                      from: edgeView.end.id,
                      to: edgeView.start.id
                  }
                });
                setHoverEdgeIndex(null);
              }
            }}
            onMouseOver={(e: any) => {
              if (!movingLine)
                setHoverEdgeIndex(edgeIndex);
            }}
            onMouseOut={(e: any) => {
              if (!movingLine)
                setHoverEdgeIndex(null);
            }}
          />
        </g>
      ))}
    </svg>
  )
}

export default XDGraph;