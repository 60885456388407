import React, { useState, useEffect } from "react";
import { Input, Button, Icon, Dropdown, TextArea, Checkbox } from "semantic-ui-react";
import { Step, ExchangeNode } from "./TypeDef";
import { NodeSelector } from "./NodeAdmin";

export type Loc = {
  step: Step,
  id?: string,
  node?: ExchangeNode,
}

export const sectionMargin = 25;
export const sectionTitle = 20;

export const getRoleType = (roleType: string) => {
  return roleType === "editor" ? "#fff2cc" 
          : roleType === "reviewer" ? "#eeeeee"
          : roleType === "node" ? "cyan" : "white"
}

export const NodeDetail = (props: any) => {
  
  const [node, setNode] = useState<any>(null);
  const [addedNodes, setAddedNodes] = useState<any[]>([]);
  
  return (
    props.xddata?.nodeMaster ? 
    <div style={{display: "flex"}}>
      <div style={{padding: "20px", flex: 1}}>
        <NodeSelector
          node={node}
          setNode={setNode}
          xdmaster={props.xdmaster}
          xddata={props.xddata}
        />
      </div>
      <div style={{padding: "20px", flex: 1}}>
        <div style={{display: "flex"}}>
          <Button disabled={!node} size="mini" color="blue"
            onClick={(e: any) => {
              if (addedNodes.map((addedNode: any) => addedNode.id).includes(node.id))
                return
              setAddedNodes([...addedNodes, node]);
            }}>
            Add
          </Button>
          <div style={{flex: 1}}></div>
          <div>
            <Button size="mini" color="red"
              onClick={() => props.doneDetail(false, {mode: "Add"})}>
              Cancel
            </Button>
            <Button size="mini" color="green"
              onClick={() => {
                props.doneDetail(
                  addedNodes.length > 0, 
                  {
                    mode: "Add",
                    addMode: "Existing",
                    items: addedNodes
                  }
                );
              }}>
              Done
            </Button>
          </div>
        </div>
        <div>
          {addedNodes.map((addedNode: any, index: number) => (
            <div key={index} style={{marginTop: "10px"}}>
              <Icon name="delete"
                onClick={(e: any) => {
                  setAddedNodes(addedNodes.filter(
                    (filteredNode: any) => filteredNode.id !== addedNode.id)
                  );
                }}
              />
              {`${addedNode.name} (${addedNode.system})`}
            </div>
          ))}
        </div>
      </div>
    </div>
    :
    <></>
  )
}

export const SectionDetail = (props: any) => {
  const [edited, setEdited] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [inactive, setInactive] = useState(false);
  const [showEn, setShowEn] = useState(false);

  useEffect(() => {
    setName(props.section?.name || "");
  }, [props.section?.name])

  useEffect(() => {
    setNameEn(props.section?.nameEn || "");
  }, [props.section?.nameEn])

  useEffect(() => {
    setInactive(props.section?.inactive || false);
  }, [props.section?.inactive])

  return(
    <div style={{padding: "20px"}}>
      <div>
        <Input 
          fluid
          value={name}
          onChange={(e: any, v: any) => {
            if (!props.xdedit) return
            if (!edited)
              setEdited(true);
            setName(e.target.value);
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              props.doneDetail(edited, {name: name})
            }
          }}
        />
        {showEn &&
        <Input 
          fluid
          value={nameEn}
          onChange={(e: any, v: any) => {
            if (!props.xdedit) return
            if (!edited)
              setEdited(true);
            setNameEn(e.target.value);
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              props.doneDetail(edited, {nameEn: nameEn})
            }
          }}
        />
        }
      </div>
      <div style={{padding: "10px 0 10px 0", display: "flex", justifyContent: "space-between"}}>
        <Checkbox    
          checked={inactive}
          onChange={(e: any) => {
            if (!props.xdedit) return
            if (!edited)
              setEdited(true);
            setInactive(!inactive)
          }}
          label="Inactive (ซ่อน)"
        />
        <div>
          {props.debug &&
          <Button 
            size="mini" color="blue"
            onClick={async (e: any) => {
              let nameEn_ = await props.onEvent({
                message: "GoogleTranslate", 
                params: {text: name}
              });
              setEdited(true);
              setNameEn(nameEn_);
            }}
            >
            Translate
          </Button>}
          <Checkbox    
            checked={showEn}
            onClick={(e: any) => setShowEn(!showEn)}
            label="EN"
          />
        </div>
      </div>
      <Button size="mini" color="blue"
        onClick={(e: any) => {
          props.doneDetail(
            edited, 
            {name: name, nameEn: nameEn,inactive: inactive});
        }}>
        Done
      </Button>
    </div>
  )
}

export const StepDetail = (props: any) => {
  const [edited, setEdited] = useState(false);
  const [showEn, setShowEn] = useState(false);

  return(
    <div style={{padding: "10px"}}>
      {/* <div>RowDetail</div> */}
      <Input 
        fluid
        size="small"
        value={props.xddata.steps[props.loc.step.id].description || ""}
        onChange={(e: any, v: any) => {
          if (!props.xdedit) return;
          setEdited(true);
          props.setProp(
            `xddata.steps.${props.loc.step.id}.description`, 
            v.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            props.doneDetail(edited)
          }
        }}
      />
      {showEn &&
      <Input 
        fluid
        size="small"
        value={props.xddata.steps[props.loc.step.id].descriptionEn || ""}
        onChange={(e: any, v: any) => {
          if (!props.xdedit) return;
          setEdited(true);
          props.setProp(
            `xddata.steps.${props.loc.step.id}.descriptionEn`, 
            v.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            props.doneDetail(edited)
          }
        }}
      />
      }
      <div 
        style={{ 
          display: "flex", alignItems: "center",
          justifyContent: "flex-end", marginTop: "10px" 
        }}>
        {props.debug &&
        <Button size="mini" color="blue"
          onClick={async (e: any) => {
            let descriptionEn = await props.onEvent({
              message: "GoogleTranslate", 
              params: {text: props.xddata.steps[props.loc.step.id].description}
            });
            setEdited(true);
            props.setProp(
              `xddata.steps.${props.loc.step.id}.descriptionEn`, 
              descriptionEn);
          }}>
          Translate
        </Button>}
        <Checkbox 
          style={{paddingRight: "5px"}}
          label="EN"
          checked={showEn}
          onClick={(e: any) => setShowEn(!showEn)}
        />
        <Button 
          color="green" 
          size="mini" 
          onClick={() => props.doneDetail(edited)}>
          Done
        </Button>
      </div>
    </div>
  )
}

export const CellDetail = (props: any) => {
  const [confirm, setConfirm] = useState(false);
  const [edited, setEdited] = useState(false);
  const [showEn, setShowEn] = useState(false);

  let step: Step | null = null;
  if (props.loc !== null) {
    // step = props.loc.step
    step = props.xddata.steps[props.loc.step.id];
  } 
  
  return(
    <div style={{ padding: "10px", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
        <div>Name: {step?.description}</div>
        <div>{`Node: ${props.loc?.node?.name} (${props.loc?.node?.system})`}</div>
      </div>
      <Input 
        fluid
        size="small"
        value={step?.nodeDetail?.[props.loc.id]?.detail || ""}
        onChange={(e: any, v: any) => {
          if (!props.xdedit) return;
          setEdited(true);
          props.setProp(
            `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.detail`, 
            v.value);
        }}
      />
      {showEn &&
      <Input 
        fluid
        size="small"
        value={step?.nodeDetail?.[props.loc.id]?.detailEn || ""}
        onChange={(e: any, v: any) => {
          if (!props.xdedit) return;
          setEdited(true);
          props.setProp(
            `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.detailEn`, 
            v.value);
        }}
      />
      }
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Dropdown
          selection={true}
          options={[
            {"key": "editor", "value": "editor", "text": "Editor"},
            {"key": "reviewer", "value": "reviewer", "text": "Reviewer"},
          ]}
          onChange={(e: any, v: any) => {
            if (!props.xdedit) return;
            setEdited(true);
            props.setProp(
              `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.type`, 
              v.value);
          }}
          value={step?.nodeDetail?.[props.loc.id]?.type || ""}
        />
        <div>
          {props.debug &&
          <Button 
            size="mini" color="blue"
            onClick={async (e: any) => {
              let detailEn = await props.onEvent({
                message: "GoogleTranslate", 
                params: {text: step?.nodeDetail?.[props.loc.id]?.detail}
              });
              setEdited(true);
              props.setProp(
                `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.detailEn`, 
                detailEn);
            }}
            >
            Translate
          </Button>}
          <Checkbox 
            checked={showEn}
            onClick={(e: any) => setShowEn(!showEn)}
            label="EN" 
          />
        </div>
      </div>
      {props.showNote &&
      <div style={{marginTop: "10px", width: "100%"}}>
        <textarea 
          style={{width: "100%"}}
          rows={30}
          value={step?.nodeDetail?.[props.loc.id]?.extraDetail || ""}
          onChange={(e: any) => {
            if (!props.xdedit) return;
            setEdited(true);
            props.setProp(
              `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.extraDetail`, 
              e.target.value);
          }}
        />
      </div>}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
        {confirm ? 
        <div>
          <Button 
            color="orange" 
            size="mini" 
            onClick={() => setConfirm(false)}>
            Cancel
          </Button>
          <Button 
            color="red"
            size="mini" 
            onClick={() => props.remove(props.loc)}>
            Confirm Remove?
          </Button>
        </div>
        :
        <div>
          <Button 
            color="green" 
            size="mini" 
            disabled={!props.xdedit}
            onClick={() => {
              // Enforce selecting type (editor, reviewer, etc)
              if (!step?.nodeDetail?.[props.loc.id]?.type)
                props.setProp(
                  `xddata.steps.${props.loc.step.id}.nodeDetail.${props.loc.id}.type`, 
                  "reviewer");
              props.doneDetail(edited)
            }}>
            Done
          </Button>
          <Button 
            color="orange" 
            size="mini" 
            disabled={!props.xdedit}
            onClick={() => setConfirm(true)}>
            Remove
          </Button>
        </div>
        }
      </div>
    </div>
  )
}
