import React, { useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import { StepView } from "./TypeDef";
import { CellDetail } from "./XdDetail";
import DocumentManager from "./DocumentManager";

const StepEditor = (props: any) => {
  
  const [contentMode, setContentMode] = useState(
    Object.keys(props.loc.step.nodeDetail || {})
      .includes(props.loc.id) ? "Document" : "General");
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setContentMode(Object.keys(props.loc.step.nodeDetail || {})
    .includes(props.loc.id) ? "Document" : "General")
  }, [props.loc]);

  const stepDict: {[id: string]: StepView} = props.view.stepDict;

  if (!props.loc?.step)
    return <></>

  const stepId = props.loc.step.id;
  const step = stepDict[stepId];

  return(
    <div style={{height: "100%", padding: "5px"}}>
      {props.editMode === "Description" ?
      <div>
        <Input 
          fluid
          size="small"
          value={step.name || ""}
          onChange={(e: any, v: any) => {
            if (!props.xdedit) return;
            setEdited(true);
            props.setProp(
              `xddata.steps.${stepId}.description`, 
              v.value);
          }}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
          <Button 
            disabled={!edited || !props.xdedit}
            color="green" 
            size="mini" 
            onClick={() => {
              if (!props.xdedit) return;
              props.onEvent({message: "SaveXdStep", params: {step: props.loc.step}});
              setEdited(false);
            }}>
            Done
          </Button>
        </div>
      </div>
      
      : props.editMode === "Role" ?
      <div style={{height: "100%", display: "flex", flexDirection: "column", position: "relative"}}>
        <div 
          style={{
            width: "100%", 
            display: "flex", 
            justifyContent: "flex-start",
            borderBottom: "solid #cccccc 1px",
            marginBottom: "5px"
          }}>
          <Button 
            color={contentMode === "General" ? "blue" : undefined}
            onClick={(e: any) => {
              setContentMode("General")
            }}
            size="mini">
            General
          </Button>
          <Button 
            disabled={!Object.keys(props.loc.step.nodeDetail || {}).includes(props.loc.id)}
            color={contentMode === "Document" ? "blue" : undefined}
            onClick={(e: any) => {
              setContentMode("Document")
            }}
            size="mini">
            Document
          </Button>
        </div>
        <div style={{flex: 1}}>
          {contentMode === "General" ?
          <CellDetail
            xdedit={props.xdedit}
            debug={props.debug}
            onEvent={props.onEvent}
            loc={props.loc}
            xddata={props.xddata}
            setProp={props.setProp}
            remove={props.remove}
            doneDetail={props.doneCellDetail}
            showNote={true}
          />
          : contentMode === "Document" ?
          <DocumentManager 
            xdedit={props.xdedit}
            debug={props.debug}
            onEvent={props.onEvent}
            stepId={stepId}
            loc={props.loc}
            trigger={props.trigger}
            docInfo={props.docInfo}
          />

          :
          <></>}
        </div>        
      </div>

      : 
      <></>}
      
    </div>
  )
}

export default StepEditor;