import React, { useState } from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import { StepView } from "./TypeDef";
import { getFromSteps } from "./Algorithm";
import VideoRequest from "../VDO/VideoRequest";

const StepPanel = (props: any) => {
  const [edited, setEdited] = useState(false);
  const [mode, setMode] = useState("Detail");

  const stepDict: {[id: string]: StepView} = props.view.stepDict;
  const stepId = props.loc.step.id;
  const step = stepDict[stepId];
  const fromSteps = getFromSteps(stepId, stepDict);

  return(
    <div>
      {props.xdedit &&
      <div style={{display: "flex", marginBottom: "2px"}}>
        <div 
          style={{
            border: "solid grey 1px", 
            padding: "0 5px 0 5px", cursor: "pointer",
            backgroundColor: mode === "Detail" ? "lightblue" : "white"
          }}
          onClick={(e: any) => setMode("Detail")}>
          Detail
        </div>
        <div 
          style={{
            border: "solid grey 1px", cursor: "pointer",
            marginLeft: "10px", padding: "0 5px 0 5px",
            backgroundColor: mode === "Line Helper" ? "lightblue" : "white"
          }}
          onClick={(e: any) => setMode("Line Helper")}>
          Line helper
        </div>
      </div>}
      {mode == "Detail" && step ?
      <div style={{padding: "10px"}}>
        <div style={{display: "flex", alignItems: "center", width: "100%", marginBottom: "10px"}}>
          {props.investigate &&
          <div style={{marginRight: "10px"}}>
            <Icon 
              name="angle left"
              onClick={(e: any) => {
                props.setInvestigate(false);
                props.setLoc({ step: props.loc.step});
              }}
            />
          </div>}
          <div 
            style={{
              flex: 1, 
              display: "flex",
              alignItems: "center",
              border: props.investigate && props.editMode === "Description" 
                      ? "solid red 1px" : "none"
            }}
            onClick={(e: any) => {
              props.setEditMode("Description");
            }}>
            <div>
              {`${step.sectionIndex + 1}.${step.stepIndex + 1}`}
            </div>
            <div style={{flex: 1}}>
              {props.investigate ?
              <div style={{marginLeft: "10px", cursor: "pointer"}}>
                {step.name || ""}
              </div>
              :
              <Input 
                fluid
                size="small"
                value={step.name || ""}
                onChange={(e: any, v: any) => {
                  if (!props.xdedit) return;
                  setEdited(true);
                  props.setProp(
                    `xddata.steps.${stepId}.description`, 
                    v.value);
                }}
              />}
            </div>
          </div>
          {!props.investigate &&
          <div style={{marginLeft: "10px"}}>
            <Icon 
              name="angle right"
              onClick={(e: any) => {
                props.setInvestigate(true);
                props.setLoc({ step: props.loc.step});
                props.setEditMode("Description");
              }}
            />
          </div>}
        </div>
        {props.debug &&
        <VideoRequest 
          onEvent={props.onEvent}
          readOnly={!props.xdedit}
          from="xd"
          detail={{
            xdId: props.xdId,
            stepId: props.loc?.step?.id
          }}
        />}
        <div style={{marginTop:"5px"}}>{`Step Id: ${stepId}`}</div>
        <div style={{marginBottom: "10px"}}>
          <div style={{display: "flex"}}>
            <b style={{textDecoration: "underline"}}>Roles</b>
            <div 
              style={{paddingLeft: "5px", paddingRight: "5px", cursor: "pointer", fontWeight: "bold"}}
              onClick={(e: any) => {
                props.setShowRoleEditor(true);
              }}>
              <Icon name="edit" />
            </div>
          </div>
          {Object.entries(step.step.nodeDetail)
            .filter(([nodeId, detail]: [string, any], nodeIndex: number) =>
              detail.type === "editor")
            .map(([nodeId, detail]: [string, any], nodeIndex: number) => 
              <RoleDetail 
                key={nodeIndex}
                nodeId={nodeId}
                detail={detail}
                nodeMaster={props.nodeMaster}
                investigate={props.investigate}
                editMode={props.editMode}
                setEditMode={props.setEditMode}
                selectedRole={props.selectedRole}
                setSelectedRole={props.setSelectedRole}
                step={step.step}
                setLoc={props.setLoc}
              />
            )}
          {Object.entries(step.step.nodeDetail)
            .filter(([nodeId, detail]: [string, any], nodeIndex: number) =>
              detail.type === "reviewer")
            .map(([nodeId, detail]: [string, any], nodeIndex: number) => 
              <RoleDetail 
                key={nodeIndex}
                nodeId={nodeId}
                detail={detail}
                nodeMaster={props.nodeMaster}
                investigate={props.investigate}
                editMode={props.editMode}
                setEditMode={props.setEditMode}
                selectedRole={props.selectedRole}
                setSelectedRole={props.setSelectedRole}
                step={step.step}
                setLoc={props.setLoc}
              />
            )}
        </div>
        <div style={{marginBottom: "10px"}}>
          <b style={{textDecoration: "underline"}}>From Steps</b>
          {fromSteps.length === 0 ? <div>-</div> :
          fromSteps.map((fromStep: StepView, fromIndex: number) => (
            <div 
              key={fromIndex}
              style={{cursor: "pointer"}}
              onClick={(e: any) => {
                props.setLoc({step: stepDict[fromStep.id].step});
                if (!props.investigate)
                  props.stepRefs[fromStep.id].current?.scrollIntoView({block: "center"});
                else
                  props.setEditMode("Description");
              }}>
              {`${fromStep.sectionIndex + 1}.${fromStep.stepIndex + 1}. ${fromStep.name}`
              + ` (${fromStep.sectionIndex + 1}. ${fromStep.sectionName})`}
            </div>
          ))}
        </div>
        <div style={{marginBottom: "10px"}}>
          <b style={{textDecoration: "underline"}}>To Steps</b>
          {step.dependOn.length === 0 ? <div>-</div> :
          step.dependOn.map((nextStepId: any, index: number) => {
            const item = stepDict[nextStepId];
            return(
              <div 
                key={index}
                style={{cursor: "pointer"}}
                onClick={(e: any) => {
                  props.setLoc({step: item.step});
                  if (!props.investigate)
                    props.stepRefs[nextStepId].current?.scrollIntoView({block: "center"});
                  else
                    props.setEditMode("Description");
                }}>
                {`${item.sectionIndex + 1}.${item.stepIndex + 1}. ${item.name}`
                + ` (${item.sectionIndex + 1}. ${item.sectionName})`}
              </div>
            )
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
          <Button 
            disabled={!edited || !props.xdedit}
            color="green" 
            size="mini" 
            onClick={() => {
              if (!props.xdedit) return;
              props.onEvent({message: "SaveXdStep", params: {step: props.loc.step}});
              setEdited(false);
            }}>
            Done
          </Button>
        </div>
      </div>

      : mode === "Line Helper" ?
      <div style={{height: "86vh", overflowY: "scroll"}}>
        {(props.xddata?.sections || [])
          .filter((section: any) => !section.inactive)
          .map((section: any, sectionIndex: number) => (
          <div key={sectionIndex}>
            <div style={{backgroundColor: "#e75480", color: "white"}}>
              {`${sectionIndex + 1}. ${section.name}`}
            </div>
            {section.steps.map((stepId: string, stepIndex: number) => (
              <div
                key={stepIndex}
                style={{
                  backgroundColor: props.toStep === stepId ? "yellow" : "white"
                }}
                onDragLeave={(e: any) => {
                  e.preventDefault();
                  if (!props.xdedit) return
                  props.setToStep(null);
                }}
                onDragOver={(e: any) => {
                  e.preventDefault();
                  if (!props.xdedit) return
                  if (props.dragMode === "Edge" 
                      && props.fromStep && props.fromStep !== stepId
                  ) {
                    props.setToStep(stepId);
                  }
                }}>
                {`${sectionIndex + 1}.${stepIndex + 1} ${props.xddata?.steps?.[stepId]?.description}`}
              </div>
            ))}
          </div>
        ))}
      </div>

      :
      <></>}
    </div>
  )
}

// This component is used by StepPanel and XDRole
// Common props: 
//     nodeId, 
//     detail, 
//     nodeMaster
//     step (parameter for set Loc), 
//     setLoc (set Loc to edit)
//     editMode (set border) , 
// Props for XDRole: 
//     selectedNodeIds (set color font)
//     loc (set border)
// Props for StepPanel: 
//     investigate (set border), 
//     selectedRole (set border), 
//     setEditMode (set edit mode to edit role instead of step), 
//     setSelectedRole (set node to edit), 

export const RoleDetail = (props: any) => {
  const node = props.nodeMaster[props.nodeId];
  return(
    <div
      style={{
        backgroundColor: props.detail.type === "editor" ? "#fff2cc" 
          : props.detail.type === "reviewer" ? "#eeeeee" 
          : "white",
        cursor: "pointer",
        color: (props?.detail?.doc && (props?.detail?.doc?.length > 0)) ? "blue" : "red",
        marginBottom: "3px",
        padding: "2px",
        border: (props.investigate && props.editMode === "Role" 
                  && props.nodeId === props.selectedRole) ? "solid red 1px" 
                : (props.editMode === "Filter" 
                  && props.nodeId === props.selectedRole 
                  && props.loc?.step?.id === props.step?.id) ? "solid red 1px"
                : "none"
      }}
      onClick={(e: any) => {
        if (props.setEditMode) {
          props.setEditMode("Role");
          props.setSelectedRole(props.nodeId);
        }
        props.setLoc({
          step: props.step,
          id: props.nodeId,
          node: props.nodeMaster[props.nodeId]
        })
      }}>
       {`${(props.selectedNodeIds && 
                (props.selectedNodeIds?.includes(props.nodeId)) ? "*" : "")}` + 
                
      `${node.name} (${node.system}) - ${props.detail.type} - ${props.detail.detail}`}
    </div>)
}

export default StepPanel;
