import WasmController from './react-lib/frameworks/WasmController'
import { ISMORState, ISMOREvent, ISMORData, ISMORDataInitial } from './ISMORInterface'
import * as ISMORI from './ISMORHandler'
import * as RedmineI from './ismor-lib/apps/REDMINE/RedmineInterface'
import { CreateUILoad, CreateUISetData } from './ismor-lib/apps/UIGEN/CreateUIHandler'
import * as UIv2 from './ismor-lib/apps/UIGEN/CreateUIv2Handler'
import { SetEditedElementName, ChangeElementProp } from './ismor-lib/apps/UIGEN/ElementEditorHandler'
import { LoadPic2UI, ProcessPic2UI } from './ismor-lib/apps/UIGEN/Pic2UIHandler'
import { GetResolvedIssues, ProcessExcel, ProcessJSON, ProcessQML } from './ismor-lib/apps/LAB/UploadHandler'
import { MyBPlusDidmount } from './ismor-lib/apps/LAB/MyBPlusHandler'
import { ScreenBrowserDidLoad, SetScreenId } from './ismor-lib/apps/UIGEN/ScreenBrowserHandler';
import * as PDFI from './ismor-lib/apps/PDF/PDFI';
import * as Stat from './ismor-lib/apps/STAT/StatInterface';
import * as PerformanceI from "./ismor-lib/apps/MONITOR/PerformanceI";
import * as PerfI from "./ismor-lib/apps/PERF/PerfI";
import * as ScoringI from "./ismor-lib/apps/SCORE/ScoreInterface";
import * as DivGridI from "./ismor-lib/apps/UIGEN/DivGridInterface";
import * as XDI from "./ismor-lib/apps/XD2/XDInterface";
import * as DPI from "./ismor-lib/apps/DP/DPInterface";
import * as WebI from "./ismor-lib/apps/WEB/WebInterface";
import * as ClientI from "./ismor-lib/apps/CLIENT/ClientInterface";
import * as StaffI from "./ismor-lib/appsadmin/STAFF/StaffInterface";
import * as StoryI from "./ismor-lib/apps/STORY/StoryInterface";
import * as VideoI from "./ismor-lib/apps/IHGAME/VideoInterface";
import * as IHGI from "./ismor-lib/apps/IHGAME/IHGI";
import * as LNI from "./ismor-lib/apps/LN/LNInterface"
import * as MEDI from "./ismor-lib/apps/MED/MEDInterface";
import * as HLI from "./ismor-lib/apps/HL/HLInterface";
import * as HZI from "./ismor-lib/apps/HZ/HZInterface";
import * as VDOI from 'ismor-lib/apps/VDO/VDOInterface';
import firebase from "firebase/compat/app";
import { webConfig, adminConfig } from './_manager/firebase-config'

export default class ISMORController extends 
  WasmController<ISMORState, ISMOREvent, ISMORData> {

  user: any;
  DEBUG = false;
  menuList: string[] = [];

  appweb: any = null;
  dbweb: any;
  storageweb: any;

  ismoradmin: boolean = false;
  appadmin: any = null;
  dbadmin: any;
  storageadmin: any;
  functionsadmin: any;

  constructor(
    getState: () => ISMORState, 
    setState: (state: ISMORState, callback?: any) => void, 
    window: any,
    ismoradmin: boolean = false) 
  {
    super(getState, setState, window, ISMORDataInitial);
    this.data = ISMORDataInitial;
    this.data.FASTAPI = "https://fastapi-jran5tfbba-de.a.run.app";
    // this.data.FASTAPI = "http://localhost:8000";    
    
    // Override region hardcode in src/react-lib/frameworks/Firebase.ts
    // this.functions = firebase.apps.find((item: any) => item.name === "[DEFAULT]")?.functions()!;

    // Initialize appweb -------------------------------------------------------------
    if(!firebase.apps.find((item: any) => item.name === "web"))
      this.appweb = firebase.initializeApp(webConfig, "web");
    else
      this.appweb = firebase.apps.find((item: any) => item.name === "web");
    
    // Initialize appadmin if applicable ----------------------------------------------
    this.ismoradmin = ismoradmin;
    if (this.ismoradmin) {
      if(!firebase.apps.find((item: any) => item.name === "admin"))
        this.appadmin = firebase.initializeApp(adminConfig, "admin");
      else
        this.appadmin = firebase.apps.find((item: any) => item.name === "admin");
    } 
  }
  
  setupExtraApps = async (user: any) => {
    const cred = firebase.auth.GoogleAuthProvider.credential(user.credential);
    await this.appweb.auth().signInWithCredential(cred.idToken)
      .catch((err: any) => console.log(err));

    if (!this.ismoradmin || !this.appadmin) return;
    // const cred = firebase.auth.GoogleAuthProvider.credential(user.credential);
    await this.appadmin.auth().signInWithCredential(cred.idToken)
      .catch((err: any) => console.log(err));
  }

  handleEvent = async (e: ISMOREvent) => {
    console.log(e.message, e.params)
    switch (e.message) {
      case "ISMORDidMount":
        ISMORI.ISMORDidMount(this)
        return
      case "LoadWasm":
        await ISMORI.LoadWasm(this)
        return
      case "LoadSAPMAP":
        await ISMORI.LoadSAPMAP(this)
        return
      case "Logout":
        await ISMORI.logout(this)
        return
      case "SavePreferences":
        await ISMORI.SavePreferences(this, e.params)
        return
      case "DownloadApp":
        ISMORI.DownloadApp(this, e.params);
        return
      case "ClearNotifications":
        ISMORI.ClearNotifications(this, e.params);
        return
      case "SetPin":
        ISMORI.SetPin(this, e.params);
        return

      // Web
      case "LoadWebData":
        WebI.LoadWebData(this, e.params);
        return
      case "SaveWebData":
        WebI.SaveWebData(this, e.params);
        return

      case "AddWebPage":
        return WebI.AddWebPage(this, e.params);

      // Client
      case "GetClientData":
        ClientI.GetClientData(this, e.params);
        return

      case "UpdateSalesLog":
        ClientI.UpdateSalesLog(this, e.params);
        return

      case "CreateClientDeal":
        ClientI.CreateClientDeal(this, e.params);
        return

      case "UpdateClientDeal":
        ClientI.UpdateClientDeal(this, e.params);
        return
      
      case "UploadDealDocument":
        ClientI.UploadDealDocument(this, e.params);
        return

      case "AddNewClient":
        ClientI.AddNewClient(this, e.params);
        return
      
      case "EditExistingClient":
        ClientI.EditExistingClient(this, e.params);
        return

      case "AddDealDeliverable":
        ClientI.AddDealDeliverable(this, e.params);
        return

      case "UpdateDealDeliverable":
        ClientI.UpdateDealDeliverable(this, e.params);
        return

      case "GetKeepList":
        ClientI.GetKeepList(this, e.params);
        return

      case "UpdateKeepItem":
        ClientI.UpdateKeepItem(this, e.params);
        return

      // CP
      case "GetClientUser":
        return ClientI.GetClientUser(this, e.params);

      case "SetClientContact":
        return ClientI.SetClientContact(this, e.params);

      case "SetClientContactPermission":
        return ClientI.SetClientContactPermission(this, e.params);

      // Game
      case "GetIHGameList":
        IHGI.GetIHGameList(this, e.params);
        return
      
      case "GetIHGameListMaster":
        IHGI.GetIHGameListMaster(this, e.params);
        return

      case "SelectIHGame":
        IHGI.SelectIHGame(this, e.params);
        return

      case "AddIHGame":
        return IHGI.AddIHGame(this, e.params);

      case "AddIHGameStep":
        return IHGI.AddIHGameStep(this, e.params);
      
      case "EditIHGameStep":
        return IHGI.EditIHGameStep(this, e.params);

      case "DeleteIHGameStep":
        return IHGI.DeleteIHGameStep(this, e.params);

      case "AddRectTarget":
        IHGI.AddRectTarget(this, e.params);
        return

      case "UploadIHGameAsset":
        IHGI.UploadIHGameAsset(this, e.params);
        return

      case "MoveIHGameScreen":
        return IHGI.MoveIHGameScreen(this, e.params);

      case "PublishIHGame":
        IHGI.PublishIHGame(this, e.params);
        return

      case "SelectPublishedIHGame":
        IHGI.SelectPublishedIHGame(this, e.params);
        return

      case "IHGameTestRun":
        IHGI.IHGameTestRun(this, e.params);
        return

      case "IHGameAddLevel":
        IHGI.IHGameAddLevel(this, e.params);
        return

      case "IHGameNewPlaySession":
        IHGI.IHGameNewPlaySession(this, e.params);
        return

      case "IHGameLogPlaySession":
        IHGI.IHGameLogPlaySession(this, e.params);
        return

      case "GetGameStat":
        return IHGI.GetGameStat(this, e.params);
      
      case "EditIHGame":
        return IHGI.EditIHGame(this, e.params);

      // Staff
      case "GetStaffData":
        StaffI.GetStaffData(this, e.params);
        return

      case "UpdateStaff":
        StaffI.UpdateStaff(this, e.params);
        return

      case "UpdateStaffDetail":
        StaffI.UpdateStaffDetail(this, e.params);
        return

      case "AddStaffDetail":
        StaffI.AddStaffDetail(this, e.params);
        return

      case "EditStaff":
        StaffI.EditStaff(this, e.params);
        return

      case "SetDividend":
        StaffI.SetDividend(this, e.params);
        return

      case "SaveStaffLetter":
        StaffI.SaveStaffLetter(this, e.params);
        return

      case "AddMonthDetail":
        StaffI.AddMonthDetail(this, e.params);
        return

      // Video
      case "GetVideo":
        VideoI.GetVideo(this, e.params);
        return

      case "SaveVideo":
        VideoI.SaveVideo(this, e.params);
        return

      // Redmine
      case "RemineDidMount":
        RedmineI.RedmineDidMount(this, e.params)
        return
      case "RedmineSub":
        RedmineI.RedmineSub(this, e.params)
        return
      case "renderByProject":
        RedmineI.renderByProject(this, e.params)
        return
      case "renderByRelease":
        RedmineI.renderByRelease(this, e.params)
        return
      case "RedmineDataRow":
        RedmineI.RedmineDataRow(this, e.params)
        return
      case "GetRedmineIssueSummary":
        RedmineI.GetRedmineIssueSummary(this, e.params)
        return
      case "GetRedminePerformance":
        RedmineI.GetRedminePerformance(this, e.params);
        return
      case "GetTaskList":
        RedmineI.GetTaskList(this, e.params)
        return
      case "GetThroughput":
        RedmineI.GetThroughput(this, e.params)
        return
      case "GetPriorityDict":
        RedmineI.GetPriorityDict(this, e.params);
        return
      case "GetAssignedIssues":
        RedmineI.GetAssignedIssues(this, e.params);
        return
      case "AddNewPriority":
        RedmineI.AddNewPriority(this, e.params);
        return
      case "UpdatePriority":
        RedmineI.UpdatePriority(this, e.params);
        return
      case "AddPriorityItem":
        RedmineI.AddPriorityItem(this, e.params);
        return
      case "DeletePriorityItem":
        RedmineI.DeletePriorityItem(this, e.params);
        return
      case "GetPerformanceAll":
        RedmineI.GetPerformanceAll(this, e.params);
        return
      case "GetPerformanceForUser":
        RedmineI.GetPerformanceForUser(this, e.params);
        return
      case "GetActiveIssues":
        RedmineI.GetActiveIssues(this, e.params);
        return
      case "GetIssueLatestUpdates":
        RedmineI.GetIssueLatestUpdates(this, e.params);
        return
      case "GetRedmineProjects":
        RedmineI.GetRedmineProjects(this, e.params)
        return
      case "GetIssuesByProject":
        RedmineI.GetIssuesByProject(this, e.params)
        return
      case "GetPerformanceDev":
        RedmineI.GetPerformanceDev(this, e.params);
        return

      // CreateUI
      case "CreateUILoad":
        CreateUILoad(this, e.params)
        return
      case "CreateUISetData":
        CreateUISetData(this, e.params)
        return

      // CreateUIv2
      case "CreateUIv2Load":
        UIv2.CreateUIv2Load(this, e.params)
        return
      case "CreateUIv2SetData":
        UIv2.CreateUIv2SetData(this, e.params)
        return
      case "AdjustUIv2Size":
        UIv2.AdjustUIv2Size(this, e.params)
        return
      case "SetEditedElementId":
        UIv2.SetEditedElementId(this, e.params)
        return
      case "SetEditedElementName":
        SetEditedElementName(this, e.params)
        return
      case "AddChildElement":
        UIv2.AddChildElement(this, e.params)
        return
      case "AddTxElement":
        UIv2.AddTxElement(this, e.params)
        return
      case "RemoveElementAndChildren":
        UIv2.RemoveElementAndChildren(this, e.params)
        return
      case "SwapChildren":
        UIv2.SwapChildren(this, e.params)
        return
      case "ChangeParent":
        UIv2.ChangeParent(this, e.params)
        return
      case "ChangeElementProp":
        ChangeElementProp(this, e.params)
        return
      case "CopyJsx":
        UIv2.CopyJsx(this, e.params)
        return
      case "CopyFirebaseToken":
        UIv2.CopyFirebaseToken(this, e.params)
        return
      case "ScreenBrowserDidLoad":
        ScreenBrowserDidLoad(this, e.params)
        return
      case "SetScreenId":
        SetScreenId(this, e.params)
        return
      case "ChangeScreenData":
        UIv2.ChangeScreenData(this, e.params)
        return
      case "AddScreen":
        UIv2.AddScreen(this, e.params)
        return
      case "DuplicateScreen":
        UIv2.DuplicateScreen(this, e.params)
        return
      case "ToggleMemo":
        UIv2.ToggleMemo(this, e.params)
        return
      case "ToggleForwardRef":
        UIv2.ToggleForwardRef(this, e.params)
        return
      case "ToggleIsMounted":
        UIv2.ToggleIsMounted(this, e.params)
        return
      case "ImportElementsFromFile":
        UIv2.ImportElementsFromFile(this, e.params)
        return
      case "ImportPicture":
        UIv2.ImportPicture(this, e.params)
        return
      case "ChangeGridDimension":
        DivGridI.ChangeGridDimension(this, e.params)
        return
      case "AddGridCell":
        DivGridI.AddGridCell(this, e.params);
        return
      case "RemoveGridCell":
        DivGridI.RemoveGridCell(this, e.params);
        return
      case "ClearGridCell":
        DivGridI.ClearGridCell(this, e.params);
        return
      case "ConfirmGridCell":
        DivGridI.ConfirmGridCell(this, e.params);
        return

      case "MoveDivGridCell":
        return DivGridI.MoveDivGridCell(this, e.params);

      // LoadPic2UI
      case "LoadPic2UI":
        LoadPic2UI(this, e.params)
        return
      case "ProcessPic2UI":
        ProcessPic2UI(this, e.params)
        return
      // case "GetTaskList":
      //   GetTaskList(this, e.params)
      //   return
      case "GetResolvedIssues":
        GetResolvedIssues(this, e.params)
        return
      case "ProcessExcel":
        ProcessExcel(this, e.params)
        return
      case "ProcessJSON":
        ProcessJSON(this, e.params)
        return

      case "ProcessQML":
        ProcessQML(this, e.params)
        return

      // MyBPlus
      case "MyBPlusDidMount":
        MyBPlusDidmount(this, e.params)
        return

      // PDF
      case "GenPDF":
        PDFI.GenPDF(this, e.params);
        return
      case "RefreshPDF":
        PDFI.RefreshPDF(this, e.params);
        return
      case "SavePDF":
        PDFI.SavePDF(this, e.params);
        return
      case "PDFMakeDownload":
        PDFI.PDFMakeDownload(this, e.params);
        return
      case "PDFAddImage":
        PDFI.PDFAddImage(this, e.params);
        return
      case "PDFAddNewFile":
        PDFI.PDFAddNewFile(this, e.params);
        return
      case "PDFSetContent":
        PDFI.PDFSetContent(this, e.params);
        return
      case "EditPDFNameAndProject":
        PDFI.EditPDFNameAndProject(this, e.params);
        return

      // XD
      case "GetXDMaster":
        return XDI.GetXDMaster(this, e.params);

      case "LoadXD":
        return XDI.LoadXD(this, e.params);

      case "SaveXD":
        return XDI.SaveXD(this, e.params);
      
      case "AddXD":
        return XDI.AddXD(this, e.params);

      case "ChangeXD":
        return XDI.ChangeXD(this, e.params);

      case "AddSection":
        return XDI.AddSection(this, e.params);
      
      case "MoveSection":
        return XDI.MoveSection(this, e.params);

      case "EditSection":
        return XDI.EditSection(this, e.params);

      case "AddXdStep":
        return XDI.AddXdStep(this, e.params);

      case "SaveXdStep":
        return XDI.SaveXdStep(this, e.params);

      case "ModifyStepRole":
        return XDI.ModifyStepRole(this, e.params);

      case "MoveXdStep":
        return XDI.MoveXdStep(this, e.params);

      case "SaveNode":
        return XDI.SaveNode(this, e.params);
      
      case "AddNode":
        return XDI.AddNode(this, e.params);

      case "SwapNode":
        return XDI.SwapNode(this, e.params);

      case "MoveNode":
        return XDI.MoveNode(this, e.params);

      case "MoveEdge":
        return XDI.MoveEdge(this, e.params);
      
      case "SwapStep":
        return XDI.SwapStep(this, e.params);
      
      case "AddXdDependOn":
        return XDI.AddXdDependOn(this, e.params);

      case "RemoveXdDependOn":
        return XDI.RemoveXdDependOn(this, e.params);
    
      case "ToggleXdDependOnOptional":
        return XDI.ToggleXdDependOnOptional(this, e.params);

      // Replaced by NodeDetailDoc --------------------------------
      // case "SaveXdStepImage":
      //   return XDI.SaveXdStepImage(this, e.params);
      
      // case "GetXdStepImage":
      //   return XDI.GetXdStepImage(this, e.params);

      // case "DeleteXdStepImage":
      //   return XDI.DeleteXdStepImage(this, e.params);
      
      // case "SaveXdStepVideo":
      //   return XDI.SaveXdStepVideo(this, e.params);
      // ----------------------------------------------------------
      
      case "ExportGridToExcel":
        return XDI.ExportGridToExcel(this, e.params);

      case "SaveNodeDetailDoc":
        return XDI.SaveNodeDetailDoc(this, e.params);

      case "GetNodeDetailDoc":
        return XDI.GetNodeDetailDoc(this, e.params);

      case "DownloadSpec":
        return XDI.DownloadSpec(this, e.params);
      
      case "GetXdCaseList":
        return XDI.GetXdCaseList(this, e.params);
      
      case "AddXdCaseSeq":
        return XDI.AddXdCaseSeq(this, e.params);

      case "DeleteXdCaseSeq":
        return XDI.DeleteXdCaseSeq(this, e.params);

      case "AddOrEditXdProblem":
        return XDI.AddOrEditXdProblem(this, e.params);

      case "AddXdCase":
        return XDI.AddXdCase(this, e.params);

      case "EditXdCase":
        return XDI.EditXdCase(this, e.params);

      case "AddXdTest":
        return XDI.AddXdTest(this, e.params);

      case "GetXdTestList":
        return XDI.GetXdTestList(this, e.params);

      case "CloseXdTest":
        return XDI.CloseXdTest(this, e.params);

      case "UpdateXdTest":
        return XDI.UpdateXdTest(this, e.params);

      case "GetXdTestSummary":
        return XDI.GetXdTestSummary(this, e.params);

      case "SwapXdCaseSeq":
        return XDI.SwapXdCaseSeq(this, e.params);

      case "PrepareXdJourneyData":
        return XDI.PrepareXdJourneyData(this, e.params);

      case "XdPublish":
        return XDI.XdPublish(this, e.params);

      case "GoogleTranslate":
        return XDI.GoogleTranslate(this, e.params);

      // Story
      case "GetStoryMaster":
        return StoryI.GetStoryMaster(this, e.params);
      case "UpdateStory":
        return StoryI.UpdateStory(this, e.params);
      case "SearchImage":
        return StoryI.SearchImage(this, e.params);

      // Stat
      case "GetIHUserProfile":
        Stat.GetIHUserProfile(this, e.params);
        return

      case "GetStatList":
        Stat.GetStatList(this, e.params);
        return

      // Performance
      case "GetApiAnalysisData":
        PerformanceI.GetApiAnalysisData(this, e.params);
        return

      // Perf
      case "GetPerfData":
        PerfI.GetPerfData(this, e.params);
        return
      
      // Scoring
      case "GetScoring":
        ScoringI.GetScoring(this, e.params);
        return

      case "GetScoringContest":
        ScoringI.GetScoringContest(this, e.params);
        return

      case "SaveContest":
        ScoringI.SaveContest(this, e.params);
        return

      // DP
      case "DPGetData":
        DPI.DPGetData(this, e.params);
        return

      case "MasterCreate":
        return DPI.MasterCreate(this, e.params);

      case "MasterGetData":
        return DPI.MasterGetData(this, e.params);

      case "MasterAddTable":
        return DPI.MasterAddTable(this, e.params);

      case "MasterUpdateTable":
        return DPI.MasterUpdateTable(this, e.params);

      case "MasterSchemaUpdate":
        return DPI.MasterSchemaUpdate(this, e.params);

      case "MasterMoveTableInTree":
        return DPI.MasterMoveTableInTree(this, e.params);

      case "MasterTreeGroup":
        return DPI.MasterTreeGroup(this, e.params);

      // LN
      case "LNGetList":
        return LNI.GetList(this, e.params);

      case "LNGetLessonData":
        return LNI.GetLessonData(this, e.params);

      case "LNUploadVideo":
        return LNI.UploadVideo(this, e.params);

      // VDO
      case "GetVDORequest":
        return VDOI.GetVDORequest(this, e.params);

      case "AddVDORequest":
        return VDOI.AddVDORequest(this, e.params);

      // MED
      case "MEDGetList":
        return MEDI.MEDGetList(this, e.params);
      
        case "MEDGetItem":
        return MEDI.MEDGetItem(this, e.params);

      case "MEDUpdateItem":
        return MEDI.MEDUpdateItem(this, e.params);

      // HL
      case "HLGetList":
        return HLI.HLGetList(this, e.params);

      case "HLGetItem":
        return HLI.HLGetItem(this, e.params);

      // HZ
      case "HZGetList":
        return HZI.HZGetList(this, e.params);

      case "HZGetItem":
        return HZI.HZGetItem(this, e.params);
      
      default:
        let testExhausitve: never = e
    }
    console.log("-- Unhandled case")
  }
}

 // Master
// case "MasterDidMount":
//   return MasterI.MasterDidmount(this, e.params);

// case "MasterGetDb":
//   return MasterI.MasterGetDb(this, e.params);

// case "MasterExec":
//   return MasterI.MasterExec(this, e.params);

// case "MasterAnalyze":
//   return MasterI.MasterAnalyze(this, e.params);

// case "MasterDumpDb":
//   return MasterI.MasterDumpDb(this, e.params);