import React, { useState, useRef, useEffect, createRef } from "react";
import { Icon, Button, Checkbox } from "semantic-ui-react";
import { NodeDetailDocItem } from "./TypeDef";

const DocumentManager = (props: any) => {
  const [cvLoaded, setCvLoaded] = useState(false);
  const [doc, setDoc] = useState<NodeDetailDocItem[]>([]);
  const [showImage, setShowImage] = useState(false);
  const [showIconIndex, setShowIconIndex] = useState<null|number>(null);
  const [insertPicIndex, setInsertPicIndex] = useState<null|number>(null);
  const [showEn, setShowEn] = useState(false);
  
  const fileRef = useRef<any>();
  const imageRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const cvRef = useRef<any>();

  useEffect(() => {
    loadCv(() => {
      cvRef.current = (window as any).cv;
      setCvLoaded(true);
    });
  }, [])

  useEffect(() => {
    props.onEvent({
      message: "GetNodeDetailDoc", 
      params: {stepId: props.stepId, loc: props.loc}}
    );
  }, [props.loc]);

  useEffect(() => {
    setDoc(props.docInfo);
  }, [props.docInfo]);

  const loadCv = (callback: any) => {
    const existingScript = document.getElementById("opencv");
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'opencv';
      script.src = "/opencv.js";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      }
    }
    if (existingScript && callback) callback();
  }

  const handlePasteFiles = async (files: File[]) => {
    if (!(imageRef?.current)) return
    let addedDoc: any[] = [];
    setShowImage(true);  
    const handleEachFile = async (file: File) => {
      const arrayBuffer = await readFileAsync(file) as ArrayBuffer;
      const picUrl = URL.createObjectURL(new Blob([arrayBuffer]));
      const img = await new Promise(resolve => {
        const img1 = new Image();
        img1.onload = async (event: any) => {
          if (!cvRef.current || !containerRef.current) return;
          const cv = cvRef.current;
          const orig = cv.imread(event.target);
          cvRef.current.imshow(imageRef.current, orig);      
          
          resolve(img1);
        };
        img1.src = picUrl;
      }); 
      addedDoc.push({ type: "image", detail: imageRef.current?.toDataURL() || ""});
    }

    // Run through all files
    for (const file of files) {
      if (!(file.type.includes("image"))) continue;
      await handleEachFile(file);
    }
    setDoc(doc.concat(addedDoc));
    setShowImage(false);
  }

  const handleUploadedFile = async (event: any) => {
    if (!props.xdedit) return;
    if (!(event.target.files[0].type.includes("image"))) return
    setShowImage(true);
    const arrayBuffer = await readFileAsync(event.target.files![0]) as ArrayBuffer;
    const picUrl = URL.createObjectURL(new Blob([arrayBuffer]));
    if (imageRef.current) {
      const img = new Image();
      img.onload = handleImageOnLoad;
      img.src = picUrl;
    }
  }

  const readFileAsync = (file: File) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
  }
  
  const handleImageOnLoad  = (event : any) => {
    if (!cvRef.current || !containerRef.current) return;
    const cv = cvRef.current;
    const orig = cv.imread(event.target);
    var final = orig
    // var final = new cv.Mat()
    // const {width, height} = orig.size();
    // const {offsetWidth, offsetHeight} = containerRef.current;
    // if (height / offsetHeight > width / offsetWidth)
    //   cv.resize(orig, final, new cv.Size(width / height * offsetHeight, offsetHeight), 0, 0, cv.INTER_AREA);
    // else
    //   cv.resize(orig, final, new cv.Size(offsetWidth, height / width * offsetWidth), 0, 0, cv.INTER_AREA);
    cvRef.current.imshow(imageRef.current, final);
    setShowImage(false);
    if (insertPicIndex === null) {
      setDoc([...doc, { type: "image", detail: imageRef.current?.toDataURL() || ""}]);
    } else {
      let newDoc: NodeDetailDocItem[] = [];
      for (const [docIndex, item] of doc.entries()) {
        newDoc.push(doc[docIndex]);
        if (docIndex === insertPicIndex)
          newDoc.push({ type: "image", detail: imageRef.current?.toDataURL() || ""})
      }
      setDoc(newDoc);
      setInsertPicIndex(null);
    }
  }

  const handleItem = (index: number, action: string) => 
    () => {
      if (!props.xdedit) return;
      let displayDocDict = Object.fromEntries(
        doc
        .map((item: any, originalIndex: number) => ({originalIndex, ...item}))
        .filter((item: any) => (!item?.removed))
        .map((item: any, displayIndex: number) => ([item.originalIndex, {displayIndex, ...item}]))
      );
      let displayLength = Object.keys(displayDocDict).length;
      if (action === "delete") 
        setDoc(doc.map(
          (item: any, docIndex: number) => 
            (docIndex === index ? {...item, removed: true} : item)));
      else if (action === "up") {
        if (displayDocDict[index].displayIndex === 0 || 
            displayLength === 1
        ) return
        const theOther: any = (Object.values(displayDocDict))[displayDocDict[index].displayIndex - 1];
        setDoc(doc.map((item: any, docIndex: number) => 
          docIndex === theOther.originalIndex ? doc[index]
          : docIndex === index ? doc[theOther.originalIndex]
          : item
        ));
      }
      else if (action === "down") {
        if (displayDocDict[index].displayIndex === displayLength - 1 || 
            displayLength === 1
        ) return
        const theOther: any = (Object.values(displayDocDict))[displayDocDict[index].displayIndex + 1];
        setDoc(doc.map((item: any, docIndex: number) => 
          docIndex === index ? doc[theOther.originalIndex]
          : docIndex === theOther.originalIndex ? doc[index]
          : item
        ));
      }
      else if (action === "text") {
        let newDoc: NodeDetailDocItem[] = [];
        for (const [docIndex, item] of doc.entries()) {
          newDoc.push(doc[docIndex]);
          if (docIndex === index)
            newDoc.push({type: "text", detail: ""})
        }
        setDoc(newDoc);
      }
      else if (action === "picture") {
        setInsertPicIndex(index);
        fileRef.current.click();
      }
    }

  return (
    <div 
      style={{height: "85vh", overflowY: "auto", width: "100%"}}
      onPasteCapture={(e: any) => {
        handlePasteFiles(e.clipboardData.files);
      }}>

      {/* Main content */}
      <div style={{display: "flex", minHeight: "5px", alignItems: "center"}}>
        <div 
          style={{
            position: "absolute", top: 0, right: 7, zIndex: 1000,
            display: "flex", alignItems: "center"
          }}>
          <>
            <Checkbox 
              checked={showEn}
              label="EN" style={{paddingRight: "10px"}}
              onClick={(e: any) => setShowEn(!showEn)}
            />
            {props.debug &&
            <Button color="blue" size="mini"
              onClick={async (e: any) => {
                let doc_ = await Promise.all(doc.map(async (item: any, index: number) => 
                  (item.type === "text" && !(item?.removed) && item.detail) ?
                  { 
                    type: "text", 
                    detail: item.detail, 
                    detailEn: await props.onEvent({
                      message: "GoogleTranslate", 
                      params: {text: item.detail}
                    })
                  } 
                  : item)
                );
                setDoc(doc_);
              }}>
              Translate
            </Button>}
          </>
          <Button size="mini" color="green" disabled={!props.xdedit}
            onClick={(e: any) => {
              if (!props.xdedit) return
              props.onEvent({
                message: "SaveNodeDetailDoc", 
                params: {doc: doc, stepId: props.stepId, loc: props.loc, cv: cvRef.current}
              });
            }}>
            Save
          </Button>
        </div>
        <div style={{flex: 1}}></div>
        <div style={{width: showEn ? "100%" : "650px", position: "relative"}}>
          {(doc || [])
          .map((item: any, originalIndex: number) => ({originalIndex: originalIndex, ...item}))
          .filter((item: any) => item?.removed !== true)
          .map((item: any, displayIndex: number) =>
            <div 
              key={displayIndex} 
              style={{position: "relative"}}
              onMouseEnter={(e: any) => {
                setShowIconIndex(displayIndex);
              }}
              onMouseLeave={(e: any) => {
                setShowIconIndex(null);
              }}
              >
              {item.type === "text" ?
              <div style={{display: "flex"}}>
                <AutoTextArea
                  value={item.detail} 
                  style={{width: "100%"}} 
                  onChange={(e: any) => {
                    if (!props.xdedit) return;
                    setDoc(doc.map((itemToChange: any, indexToChange) => 
                      indexToChange === item.originalIndex ? 
                      { type: "text", detail: e.target.value, detailEn: item.detailEn || "" } 
                        : itemToChange)
                    );
                  }}
                />
                {showEn &&
                <AutoTextArea
                  value={item?.detailEn} 
                  style={{width: "100%"}} 
                  onChange={(e: any) => {
                    if (!props.xdedit) return;
                    setDoc(doc.map((itemToChange: any, indexToChange) => 
                      indexToChange === item.originalIndex ? 
                      { type: "text", detail: item.detail || "", detailEn: e.target.value } 
                        : itemToChange)
                    );
                  }}
                />}
              </div>
              
              : item.type === "image" ?
              <div style={{display: "flex"}}>
                <img src={item.detail} style={{width: showEn ? "50%" : "100%"}} />
                {showEn &&
                <div 
                  style={{
                    width: showEn ? "50%" : "100%", backgroundColor: "lightgrey",
                    display: "flex", justifyContent: "center", alignItems: "center"
                  }}>
                  EN Image Upload (under construction)
                </div>}
              </div>
              
              :
              <></>
              }
              {showIconIndex === displayIndex &&
              <div style={{position: "absolute", right: 0, top: 0}}>
                <Icon name="delete" color="red"
                  onClick={handleItem(item.originalIndex, "delete")}
                />
                <Icon name="angle up" color="green"
                  onClick={handleItem(item.originalIndex, "up")}
                />
                <Icon name="angle down" color="green"
                  onClick={handleItem(item.originalIndex, "down")}
                />
                <Icon name="file text" color="blue"
                  onClick={handleItem(item.originalIndex, "text")}
                />
                <Icon name="picture" color="blue"
                  onClick={handleItem(item.originalIndex, "picture")}
                />
              </div>}
            </div>
          )}

          {/* Work area for opencv */}
          <div ref={containerRef} 
            style={{
              width: "100%", 
              display: showImage ? "block" : "none",
            }}>
            <canvas style={{width: "100%", height: "100%"}} ref={imageRef}/>
          </div>
        </div>
        <div style={{flex: 1}}></div>
      </div>

      {/* Button bar */}
      <div style={{display: "flex"}}>
        <Button size="mini" color="orange" icon labelPosition="left"
          disabled={!props.xdedit}
          onClick={(e: any) => {
            if (!props.xdedit) return
            setDoc([...doc, {type: "text", detail: ""}]);
          }}>
          <Icon name="plus" />
          Text
        </Button>
        <Button size="mini" color="yellow" icon labelPosition="left"
          disabled={!props.xdedit}
          onClick={(e: any) => {
            if (!props.xdedit) return
            setInsertPicIndex(null);
            fileRef.current.click();
          }}>
          <Icon name="plus" />
          Picture
        </Button>
        <input 
          ref={fileRef}
          type="file" 
          style={{display: "none"}}
          onChange={handleUploadedFile}
        />
      </div>
    </div>
  )
}

const AutoTextArea = (props: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    const height = ref.current.scrollHeight;
    ref.current.style.height = height + 2 + "px";
  }, [props.value])

  return(
    <textarea
      ref={ref}
      style={props.style}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export default DocumentManager;