import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';
import { exists } from 'fs';

export type State = {
}

export const StateInitial = {
}

export type Event =
  { message: "GetVDORequest", params: {} }
  | { message: "AddVDORequest", params: {} }

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const getRequestId = (params: any) => {
  let requestId = `from|${params.from}|` 
      + Object.entries(params.detail)
        .map((entry: [string, any]) => `${entry[0]}|${entry[1]}`).join("|");
  return requestId
}

export const GetVDORequest: Handler = async (controller, params) => {
  let { from, detail, setRequestData } = params;
  if (!from || !detail) return console.log("Insuffient params");
  if (!setRequestData) return console.log("No setVideoRequest callback");
  let requestId = getRequestId(params);
  let videoRequest = await controller.db.collection("VideoRequest").doc(requestId).get()
  if (videoRequest.exists) {
    setRequestData({exists: true, id: videoRequest.id, data: videoRequest.data()});
  } else {
    setRequestData({exists: false})
  }
}

export const AddVDORequest: Handler = async (controller, params) => {
  let { from, detail, requestData, setRequestData } = params;
  if (!from || !detail || !requestData) return console.log("Insuffient params");
  if (!setRequestData) return console.log("No setVideoRequest callback");

  if (!requestData.exists) {
    let requestId = getRequestId(params);
    await controller.db.collection("VideoRequest").doc(requestId).set({from, detail});
    setRequestData({from, detail});
  }
}
